import React, {useState, useEffect} from "react";
import axios from './Api'

const SelectData = (endpoint) => {

    const [companyLists, setCompanyLists] = useState([]);

    useEffect(() => {
        getAllAccounts();
    }, []);

    // const token = localStorage.getItem('token');

    const getAllAccounts = () => {
        console.log("endpoint", endpoint);
        if(endpoint === "" || endpoint === undefined || endpoint === null) {
        }
        else {
            axios.get(endpoint)
                .then(async (res) => {
                    // console.log(res.data);
                    if (res.data.status === 1) {
                        setCompanyLists(res.data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    return companyLists;
};

export default SelectData;
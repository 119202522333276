import React, { PureComponent } from 'react';
import {PieChart, Pie, Sector, Tooltip, Cell, ResponsiveContainer, Legend} from 'recharts';

export const AccountBalance = ({data}) => {
    // const data = [
    //     {name: 'Group A', value: 400},
    //     {name: 'Group B', value: 300},
    //     {name: 'Group C', value: 300},
    //     {name: 'Group D', value: 200},
    // ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FE','#00C49F','#FFBB28','#FF8042','#0088FE','#00C49F'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={300} height={300}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                    ))}
                </Pie>
                <Legend />
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
}

import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {numberFormatOnly} from "../../../../util/CISUI";


export const ReceiptAndPaymentChart = () => {
    const data = [
        {
            name: 'MAR 24',
            recipt: 4000,
            payment: 2400,
            amt: 2400,
        },
        {
            name: 'ARP 24',
            recipt: 3000,
            payment: 1398,
            amt: 2210,
        },
        {
            name: 'MAY 24',
            recipt: 2000,
            payment: 9800,
            amt: 2290,
        },
        {
            name: 'JUN 24',
            recipt: 2780,
            payment: 3908,
            amt: 2000,
        },
        {
            name: 'JUL 24',
            recipt: 1890,
            payment: 4800,
            amt: 2181,
        },
        {
            name: 'AUG 24',
            recipt: 2390,
            payment: 3800,
            amt: 2500,
        },
        {
            name: 'SEP 24',
            recipt: 3490,
            payment: 4300,
            amt: 2100,
        },
    ];

    class CustomizedLabel extends PureComponent {
        render() {
            const {x, y, stroke, value} = this.props;

            return (
                <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                    {value}
                </text>
            );
        }
    }

    class CustomizedAxisTick extends PureComponent {
        render() {
            const {x, y, stroke, payload} = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                        {payload.value}
                    </text>
                </g>
            );
        }
    }

    const CustomTooltip = ({ payload, label, active }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label">{`${label.toUpperCase()}`}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`}>{`${entry.name}: ${numberFormatOnly(entry.value)}`}</p>
                    ))}
                </div>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                {/*<CartesianGrid strokeDasharray="1 1" />*/}
                <XAxis dataKey="name" />
                {/*<YAxis />*/}
                <Tooltip content={CustomTooltip} />
                <defs>
                    <linearGradient id="reciept" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="75%" stopColor="#2aa9daFF" stopOpacity={0.20}/>
                        <stop offset="25%" stopColor="#B7ACEAFF" stopOpacity={0.20}/>
                    </linearGradient>
                    <linearGradient id="payment" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="75%" stopColor="#5f2daf" stopOpacity={0.20}/>
                        <stop offset="25%" stopColor="#29A5D790" stopOpacity={0.20}/>
                    </linearGradient>
                </defs>
                <Area type="monotone" dataKey="recipt" stackId="2" stroke="#2aa9da" fill="url(#reciept)" />
                <Area type="monotone" dataKey="payment" stackId="1" stroke="#5f2daf" fill="url(#payment)" />
                {/*<Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />*/}
            </AreaChart>
        </ResponsiveContainer>
    );
}

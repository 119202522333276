import React, {useState} from "react";
import {Form, Input, Collapse, notification, Popconfirm, Card} from 'antd';
import jsonfile from './localexpenses.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import SelectData from "../../../../util/selectdata";
import axios from '../../../../util/Api'
import jsPDF from 'jspdf';
import moment from 'moment';

const Panel = Collapse.Panel;

const DistrictMontlyReport = () => {
    const ref = React.createRef();
    const formRef = React.createRef();
    const [monthStart,setMonthStart] = useState("");
    const [reportClosed,setReportClosed] = useState(false);

    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.monthly,
        filtervalue: '',
        reportTitle: '',
        reportData: [],
        totalContribution: 0,
        month: "",
        Area: "",
        district: "",
        locals: [],
        localData: [],
        contribution: [],
        expenses: [],
        local_expenses: [],
        totalExpenses: 0,
        netTitheAfterExpense: 0,
        ddf: 0,
        NetTitheToDistrict: 0,
        missionOffering: 0,
        MinistryIncome: 0,
        Mckeowns: 0,
        InternalMissions: 0,
        NationalPrayer: 0,
        PENSOS: 0,
        netCashToDistrict: 0,
        district_id : 0,
        reportClosed : 0,
    });

    const history = useHistory();


    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    const mnts = CisUI().ParseDateFormatFromMonth(wk);
                    //console.log(mnts);
                    wkd = mnts;
                    return `${key}=${mnts}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.monthly + "?" + qsup;
        //console.log("NEURL :" + newURL);
        //console.log("Month : " + wkd);
        // const WKR = CisUI().ParseDateFormat(wk);

        const dts = values['month'];
        const WKR = CisUI().getMonthNameTimeStamp(dts);
        // const reportTitle = "Monthly Report For the month of "+WKR;
        setMonthStart(moment(dts).format("YYYY-MM")+"-01");

        //const reportTitle = "Between " + CisUI().DateFormat(WKR[0]) + " and " + CisUI().DateFormat(WKR[1]);
        const reportTitle = "Monthly Report For the month of "+CisUI().getMonthTitle(wkd);
        const url = Config.apiserver + jsonfile.urls.monthly;

        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                // console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data.data;
                    setState({
                        reqURL: newURL,
                        contribution: data.contribution,
                        totalContribution: data.totalContribution,
                        locals: data.locals,
                        localData: data.localData,
                        district: data.district,
                        region: data.region,
                        reportTitle: reportTitle,
                        month: CisUI().getMonthTitle(wkd),
                        expenses: data.expenses,
                        local_expenses: data.local_expenses,
                        totalExpenses: data.totalExpenses,
                        netTitheAfterExpense: data.netTitheAfterExpense,
                        ddf: data.ddf,
                        NetTitheToDistrict: data.NetTitheToDistrict,
                        missionOffering: data.missionOffering,
                        MinistryIncome: data.MinistryIncome,
                        Mckeowns: data.Mckeowns,
                        InternalMissions: data.InternalMissions,
                        NationalPrayer: data.NationalPrayer,
                        PENSOS: data.PENSOS,
                        netCashToDistrict: data.netCashToDistrict,
                        district_id : data.district_id,
                        reportClosed : data.reportClosed,
                    })
                    if(data.reportClosed > 0) {
                        setReportClosed(true)
                    }
                    else {
                        setReportClosed(false)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    };

    const MonthClose = () => {
        console.log("Month : "+monthStart);
        if(monthStart === "" || state.district_id === 0) {
            notification.warning({
                message: 'Alert',
                type : "error",
                description: "Month should be selected and report should be loaded"
            });
        }
        else {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + jsonfile.urls.MonthClose, {"month": monthStart, "district_id": state.district_id})
                .then((res) => {
                    document.getElementById("loader").style.display = "none";
                    if (res.data.status === 1) {
                        notification.warning({
                            message: 'Alert',
                            type : "success",
                            description: res.data.msg
                        });
                        setReportClosed(true);
                    }
                    else {
                        notification.warning({
                            message: 'Alert',
                            type : "error",
                            description: res.data.msg
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });
        }

    }

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input, "", 0, 'month')}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        // window.print();
        window.open(Config.baseurl+"monthlyreportprint.html?url="+window.btoa(state.reqURL)+"&filename=district_monthly_report&data=districtmonthlyData&subtitle="+state.reportTitle+"&rptype=districtmonthlyreport","_blank");
    }


    const exportToPDF = () => {

        var doc = new jsPDF({
            putOnlyUsedFonts: true,
            orientation: "landscape",
            unit: "px"
        });

        // var doc = new jsPDF('l', 'px', 'letter');

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const marginX = (pageWidth - 80) / 2;
        const xOffset = (doc.internal.pageSize.width / 2);
        const user = localStorage.getItem('userData');
        const uj = JSON.parse(user)

        doc.addImage(Config.print_logourl, "PNG", marginX, 5, 80, 27);
        doc.text("Area Summary Report",xOffset,37, { align: 'center' });
        doc.setFontSize(12);
        doc.text(state.reportTitle,xOffset,43, { align: 'center' });
        doc.setFontSize(8);
        doc.text("Printed By : "+uj["name"],20,50, { align: 'left' });
        doc.setFontSize(8);
        doc.text("Date/Time : "+moment().format("MM/DD/YYYY HH:mm"),20,55, { align: 'left' });

        var content = document.getElementById("Area_summary").innerHTML;

        doc.html(content,
            {
                margin: [40, 60, 40, 60],
                callback: function (doc) {
                    doc.save("Area_summary");
                },
                x: 20,
                y: 50
            })

    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );


    const getExtra = (
        <>
            {listAction()}
        </>
    );


    const ReportData = state.reportData;
    const expenseTypes = SelectData("donation/expensetype?area=6");

    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [4, 2]
    };

    //console.log(state.local_expenses);

    return (
        <div>
            <div className="page-break" />
            {searchView}
            <Card id="reportview" extra={getExtra}>
                {CisUI().showLoading}
                <div ref={ref}>
                    <div className="row">
                        <div className="col-md-12 text-right" style={{textAlign: 'right'}}>
                            {/*{*/}
                            {/*    state.district_id > 0 ?*/}
                            {/*        reportClosed === false ?*/}
                            {/*            <Popconfirm title="By submitting you agree that your monthly returns will be closed and entry will be disabled for this month." onConfirm={MonthClose} okText="Confirm"*/}
                            {/*                        cancelText="Cancel">*/}
                            {/*                <button className="ant-btn ant-btn-primary">*/}
                            {/*                    <i className="fas fa-unlock"/> Submit*/}
                            {/*                </button>*/}
                            {/*            </Popconfirm>*/}
                            {/*            :<button className="ant-btn ant-btn-primary" type="button"><i className="fa fa-lock"></i> Month Closed</button>*/}
                            
                            {/*        : ""*/}
                            {/*}*/}
                        </div>
                        <div className="col-md-12 text-center">
                            <h3>District Monthly Report</h3>
                            <h2>{state.reportTitle}</h2>
                        </div>
                        <div className="col-md-6">
                            <strong>Month :</strong> {state.month}
                            <div><strong>Area :</strong> {state.region}</div>
                            <div><strong>District :</strong> {state.district}</div>
                            <div><strong>NO. OF LOCALS :</strong> {state.locals.length}</div>
                        </div>
                        <div className="col-md-6 text-right">

                        </div>
                    </div>
                    <h4 style={{marginTop: '20px'}}>Local Assembly Income & Expenses</h4>
                    <table className="report table table-bordered">
                        <thead>
                        <tr>
                            <th style={{textAlign: 'left'}}>Assembly</th>
                            <th style={{textAlign: 'center'}}>Tithe & Offering</th>
                            {
                                state.local_expenses.map((items,indx)=> {
                                    return  <th style={{textAlign: 'center'}}>{items.account_name}</th>
                                })
                            }
                            <th style={{textAlign: 'center'}}>Total Expenses</th>
                            <th style={{textAlign: 'center'}}>Net Tithe</th>
                            <th style={{textAlign: 'center'}}>LDF</th>
                            <th style={{textAlign: 'center'}}>Net Tithe To District</th>
                            <th style={{textAlign: 'center'}}>Missions Offering</th>
                            {/*<th style={{textAlign: 'center'}}>Others Income</th>*/}
                            <th style={{textAlign: 'center'}}>Amount Sent To District </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            state.localData.map((items, index) => {

                                    if(items.type === 'total') {
                                        return <tr>
                                            <th>{items.local_name}</th>
                                            <th>{CisUI().getCurrency(items.tithe)}</th>
                                            {
                                                state.local_expenses.map((itm,ind)=> {
                                                    return <th style={{textAlign: 'center'}}>{items[itm.account_number]}</th>
                                                })
                                            }
                                            <th>{CisUI().getCurrency(items.total_expense)}</th>
                                            <th>{CisUI().getCurrency(items.net_tithe)}</th>
                                            <th>{CisUI().getCurrency(items.ldf)}</th>
                                            <th>{CisUI().getCurrency(items.net_to_district)}</th>
                                            <th>{CisUI().getCurrency(items.mission_offering)}</th>
                                            {/*<th>{CisUI().getCurrency(items.others_income)}</th>*/}
                                            <th>{CisUI().getCurrency(items.net)}</th>
                                        </tr>
                                    }
                                    else {
                                        return <tr>
                                            <td>{items.local_name}</td>
                                            <td>{CisUI().getCurrency(items.tithe)}</td>
                                            {
                                                state.local_expenses.map((itm,ind)=> {
                                                    return  <th style={{textAlign: 'center'}}>{items[itm.account_number]}</th>
                                                })
                                            }
                                            <td>{CisUI().getCurrency(items.total_expense)}</td>
                                            <td>{CisUI().getCurrency(items.net_tithe)}</td>
                                            <td>{CisUI().getCurrency(items.ldf)}</td>
                                            <td>{CisUI().getCurrency(items.net_to_district)}</td>
                                            <td>{CisUI().getCurrency(items.mission_offering)}</td>
                                            {/*<td>{CisUI().getCurrency(items.others_income)}</td>*/}
                                            <td>{CisUI().getCurrency(items.net)}</td>
                                        </tr>
                                    }

                                }
                            )
                        }
                        </tbody>
                    </table>
                    <h4>District Income & Expenses</h4>
                    <table className="report table table-bordered">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th style={{textAlign: 'right'}}>Amount</th>
                            <th style={{textAlign: 'right'}}>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>TOTAL CONTRIBUTION RECEIVED (Tithe & Offering)</th>
                            <th style={{textAlign: 'right'}}></th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.totalContribution)}</th>
                        </tr>

                        <tr>
                            <th>DISTRICT EXPENSES</th>
                            <th style={{textAlign: 'right'}}></th>
                            <th style={{textAlign: 'right'}}></th>
                        </tr>
                        {
                            state.expenses.map((items, index) => {
                                    return <tr>
                                        <td>{items.title}-{items.expense_id}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.amount)}</td>
                                        <td style={{textAlign: 'right'}}></td>
                                    </tr>
                                }
                            )
                        }
                        <tr>
                            <th>Total District Expenses</th>
                            <th style={{textAlign: 'right'}}></th>
                            <th style={{textAlign: 'right'}}>({CisUI().getCurrencyFormated1(state.totalExpenses)})</th>
                        </tr>

                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Net Tithe After District Expenses
                            </th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.netTitheAfterExpense)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Less: District Development Fund (DDF) : 10%</th>
                            <th style={{textAlign: 'right'}}>({CisUI().getCurrencyFormated1(state.ddf)})</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Net Tithe to Area</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.NetTitheToDistrict)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : Missions Offering</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.missionOffering)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign: 'right'}}><a href="#">Add : Ministry Income</a></th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.MinistryIncome)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : Mckeowns</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.Mckeowns)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : Internal Missions</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.InternalMissions)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : National Prayer</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.NationalPrayer)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : PENSOS</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.PENSOS)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>AMOUNT SENT TO Area</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.netCashToDistrict)}</th>
                        </tr>


                        </tbody>
                    </table>
                </div>

            </Card>

        </div>
    );
};

export default DistrictMontlyReport;
import {BellOutlined, MailOutlined, MessageOutlined} from "@ant-design/icons";
import {Col, notification, Row} from "antd";
import Widget from "components/Widget/index";
import PropTypes from "prop-types";
import jsonfile from "./dashboard.json"
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Metrics from "../../../components/Metrics";
import {
    BarChart, Bar, Legend,
    Area,
    AreaChart,
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import Auxiliary from "util/Auxiliary";
import BalanceHistory from "../../../components/dashboard/Crypto/BalanceHistory";
import ChartCard from "../../../components/dashboard/Crypto/ChartCard";
import Portfolio from "../../../components/dashboard/Crypto/Portfolio";
import {increamentData, lineData} from "../../../routes/main/Metrics/data";
import axios from "../../../util/Api";
import {CisUI} from "../../../util/CISUI";
import Config from "../../../util/config";
import IntlMessages from "../../../util/IntlMessages";
import CanvasJSReact from "./../../canvasjs/canvasjs.react";
import "./newdashboard.css";
import "./dashboard.css";

import DateBasedLine from "../../../routes/extensions/charts/amchart/line/Components/DateBasedLine";
import FillBetweenTwoLines from "../../../routes/extensions/charts/amchart/line/Components/FillBetweenTwoLines";
import CustomActiveShapePieChart
    from "../../../routes/extensions/charts/recharts/pie/Components/CustomActiveShapePieChart";
import CustomActiveShapePieChart2
    from "../../../routes/extensions/charts/recharts/pie/Components/CustomActiveShapePieChart";

import Column3DChart from "../../../routes/extensions/charts/amchart/bar/Components/ClusteredBarChart";
// import CombinedBullet from "../../../routes/extensions/charts/amchart/bar/Components/CombinedBullet";
import ChartWithGapsInData from "../../../routes/extensions/charts/amchart/line/Components/ChartWithGapsInData";
import BarChartStackedBySign from "../../../routes/extensions/charts/recharts/bar/Components/BarChartStackedBySign";
import PieChart3D from "../../../routes/extensions/charts/amchart/pie/Components/PieChart3D";
import DonutChart3D from "../../../routes/extensions/charts/amchart/pie/Components/DonutChart3D";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const AdminDashboard = (props) => {
    const userLocal = localStorage.getItem("userData") || "";
    let userID = 0;
    let userName = "";
    let user = {
        id: 0,
        name: "",
    };
    if (userLocal !== "") {
        user = JSON.parse(userLocal);
        userID = user["id"];
        userName = user["name"];
    }

    const [incomeExpense, setInocmeExpnese] = useState([]);
    const [state, setState] = useState({
        totalMember: 0,
        totalPayingMember: 0,
        totalNonPayingMember: 0,
        payingPercent: 0,
        nonPayingPercent: 0,
        totalTithe: 0,
        titheCompare: [],
        currentMonthCollection: {
            tithe: 0,
            mission: 0,
            others: 0,
            total: 0,
        },
        previousMonthCollection: {
            tithe: 0,
            mission: 0,
            others: 0,
            total: 0,
        },
        thisyearDonation: [],
        grossTithe: 0,
        allowableExpense: 0,
        netTithe: 0,
        devFund: 0,
        devFundTitle: "",
        kpi: [],
        bankReconcile: [],
        highestTithe: {
            name: "",
            amount: 0,
        },
        highestMission: {
            name: "",
            amount: 0,
        },
        titheByRegion: [],
        incomeByRestriction: [],
        cashBalance: 0,
        exenseYT: 0,
        receiveableBalance: 0,
        payableBalance: 0,
        notification: {
            notification: 0,
            bill: 0,
            po: 0,
            task: 0,
        },
        entryMenu: [],
        voucherIncomeVsPayament: [],
        PurchaseAndSales: [],
        cashOnHand: 0,
        getTotalExpenses: 0,
        getBudgetByDepartment: [],
        getTitheReport: [],
        getVouchers: [],
    });

    useEffect(() => {
        getDashboardData();
    }, []);

    const getDashboardData = () => {
        document.getElementById("loader").style.display = "block";
        axios
            .get(Config.apiserver + "dashboard", null, CisUI().HeaderRequest)
            .then((res) => {
                console.log(res);
                if (res.data.status === 1) {
                    setInocmeExpnese(res.data.incomeExpense);
                    const totalMember = res.data.totalMember;
                    const totalPayingMember = res.data.totalPayingMember;
                    const totalNonPayingMember = res.data.totalNonPayingMember;
                    const payingPercent = (
                        (totalPayingMember * 100) /
                        totalMember
                    ).toFixed(2);
                    const nonPayingPercent = (
                        (totalNonPayingMember * 100) /
                        totalMember
                    ).toFixed(2);

                    setState({
                        totalMember: totalMember,
                        totalPayingMember: totalPayingMember || 0,
                        payingPercent: payingPercent || 0,
                        totalNonPayingMember: totalNonPayingMember || 0,
                        nonPayingPercent: nonPayingPercent || 0,
                        totalTithe: res.data.totalTithe,
                        titheCompare: res.data.titheCompare,
                        currentMonthCollection: res.data.currentMonthCollection,
                        previousMonthCollection: res.data.previousMonthCollection,
                        thisyearDonation: res.data.thisyearDonation,
                        grossTithe: res.data.grossTithe,
                        allowableExpense: res.data.allowableExpense,
                        netTithe: res.data.netTithe,
                        devFund: res.data.devFund,
                        devFundTitle: res.data.devFundTitle,
                        kpi: res.data.kpi,
                        bankReconcile: res.data.bankReconcile,
                        highestTithe: res.data.highestTithe,
                        highestMission: res.data.highestMission,
                        titheByRegion: res.data.titheByRegion,
                        incomeByRestriction: res.data.incomeByRestriction,
                        exenseYT: res.data.exenseYT,
                        cashBalance: res.data.cashBalance,
                        receiveableBalance: res.data.receiveableBalance,
                        payableBalance: res.data.payableBalance,
                        notification: res.data.notification,
                        entryMenu: res.data.entryMenu,
                        voucherIncomeVsPayament: res.data.voucherIncomeVsPayament,
                        PurchaseAndSales: res.data.PurchaseAndSales,
                        cashOnHand: res.data.cashOnHand,
                        getTotalExpenses: res.data.getTotalExpenses,
                        getBudgetByDepartment: res.data.getBudgetByDepartment,
                        getTitheReport: res.data.getTitheReport,
                        getVouchers: res.data.getVouchers,
                    });
                } else {
                    notification.warning({
                        message: "Alert",
                        type: "warning",
                        description: res.data.msg,
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch((errors) => {
                // console.log(errors.response.data.errors);
                console.log(errors);
                //ocument.getElementById("loader").style.display = "none";
            });
    };

    CanvasJS.addColorSet("brandedset", [
        "#2F4F4F",
        "#008080",
        "#2E8B57",
        "#3CB371",
        "#90EE90",
    ]);

    const colorPlates = [
        "#142850",
        "#27496d",
        "#0c7b93",
        "#00a8cc",
        "#8566aa",
        "#6983aa",
        "#8ec6c5",
        "#4d4c7d",
        "#2c003e",
        "#7f78d2",
        "#1eb2a6",
    ];

    // const colors = scaleOrdinal(schemeCategory10).range();

    const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
        x + width / 2
    }, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
        y + height
    } ${x + width}, ${y + height}
          Z`;

    const TriangleBar = (props) => {
        const {fill, x, y, width, height} = props;

        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
    };

    TriangleBar.propTypes = {
        fill: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    };

    const titheByRegion = {
        animationEnabled: true,
        colorset: colorPlates,
        axisX: {
            title: "Area",
        },
        axisY: {
            title: "Amount",
        },
        toolTip: {
            shared: true,
        },
        legend: {
            cursor: "pointer",
        },
        labelLine: false,
        theme: "light2", //"light1", "dark1", "dark2"
        data: [
            {
                type: "column",
                name: "Tithe",
                showInLegend: false,
                color: "#00A8CCFF",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.titheByRegion,
            },
        ],
    };

    const QuickRatio = {
        animationEnabled: true,
        title: {
            text: "Quick Ratio",
        },
        subtitles: [
            {
                text: "1.10% Positive",
                verticalAlign: "center",
                fontSize: 24,
                dockInsidePlotArea: true,
            },
        ],
        data: [
            {
                type: "doughnut",
                showInLegend: true,
                indexLabel: "{name}: {y}",
                yValueFormatString: "#,###'%'",
                colorSet: "brandedset",
                dataPoints: [
                    {name: "Cash Equivalent", y: 5},
                    {name: "Marketable Security", y: 30},
                    {name: "Accounts receivable", y: 25},
                    {name: "Current Liabilities", y: 40},
                ],
            },
        ],
    };

    const IncomeByRestriction = {
        animationEnabled: true,
        data: [
            {
                type: "doughnut",
                showInLegend: true,
                indexLabel: "{name}: {y}",
                yValueFormatString: "$#,###",
                colorSet: "brandedset",
                dataPoints: state.incomeByRestriction,
            },
        ],
    };

    const width = 400;

    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <Auxiliary>
            {CisUI().showLoading}

            <Row>
                <Col span={24}>
                    <div className="row">
                        <div className="col-md-4">

                            <div className="gx-card gx-card-border-red">
                                <div className="gx-card-body">
                                    <div className="gx-wel-ema gx-pt-xl-2">
                                        <h1 className="gx-mb-3">
                                            <IntlMessages id={"Welcome"}/> {user.name}!
                                        </h1>
                                        <p className="gx-fs-sm gx-text-uppercase">
                                            <IntlMessages id={"You Have"}/>
                                        </p>
                                        <ul className="gx-list-group">
                                            <li>
                                                <MessageOutlined/>
                                                <span>
                          <Link to={"../vendor/pendingbill"}>
                            {state.notification.bill} Bill Approval request(s)
                          </Link>
                        </span>
                                            </li>
                                            <li>
                                                <BellOutlined/>
                                                <span>
                          <Link to={"../user/notification"}>
                            {state.notification.notification} notifications
                          </Link>
                        </span>
                                            </li>
                                            <li>
                                                <MailOutlined/>
                                                <span>
                          <Link to={"../purchase/pendingpo"}>
                            {state.notification.po} Pending PO's
                          </Link>
                        </span>
                                            </li>
                                            <li>
                                                <MailOutlined/>
                                                <span>
                          <Link to={"../crm/tasklist"}>
                            {state.notification.task} Task
                          </Link>
                        </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="gx-card gx-card-border-blue">
                                <div className="gx-card-body">
                                    <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
                                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">
                                            <IntlMessages id={"Favourite"}/>
                                        </h6>

                                        {/*<div className="row">*/}
                                        {/*    {*/}
                                        {/*        state.entryMenu.map((items, index) => {*/}
                                        {/*            return <div className="col-md-6">*/}
                                        {/*                <p className="gx-fs-sm gx-text-uppercase">*/}
                                        {/*                    <strong>{items.name}</strong>*/}
                                        {/*                </p>*/}
                                        {/*                <ul className="gx-list-group" style={{paddingLeft: '10px'}}>*/}
                                        {/*                    {*/}
                                        {/*                        items.sub.map((mnu, indx) => {*/}
                                        {/*                            return <li>*/}
                                        {/*                                    <span>*/}
                                        {/*                                        <Link to={"../../" + mnu.url}><i*/}
                                        {/*                                            className="fa fa-dot-circle"></i> {mnu.name}</Link>*/}
                                        {/*                                    </span>*/}
                                        {/*                            </li>*/}
                                        {/*                        })*/}
                                        {/*                    }*/}
                                        {/*                </ul>*/}
                                        {/*            </div>*/}
                                        {/*        })*/}
                                        {/*    }*/}
                                        {/*</div>*/}

                                    </div>
                                </div>
                            </div>

                            <div className="gx-card gx-card-border-gold">
                                <div className="gx-card-body">
                                    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">
                                            <IntlMessages id={"Income & Expenditure"}/>
                                        </h6>
                                        <ResponsiveContainer width="100%" height={140}>
                                            <AreaChart data={incomeExpense}>
                                                <defs>
                                                    <linearGradient
                                                        id="colorIncome"
                                                        x1="0"
                                                        y1="0"
                                                        x2="0"
                                                        y2="1"
                                                    >
                                                        <stop
                                                            offset="5%"
                                                            stopColor="#27496d"
                                                            stopOpacity={0.8}
                                                        />
                                                        <stopgetDashboardData
                                                            offset="95%"
                                                            stopColor="#00a8cc"
                                                            stopOpacity={0.4}
                                                        />
                                                    </linearGradient>
                                                    <linearGradient
                                                        id="colorExpnese"
                                                        x1="0"
                                                        y1="0"
                                                        x2="0"
                                                        y2="1"
                                                    >
                                                        <stop
                                                            offset="5%"
                                                            stopColor="#bf163f"
                                                            stopOpacity={0.8}
                                                        />
                                                        <stop
                                                            offset="95%"
                                                            stopColor="#bf163f"
                                                            stopOpacity={0.4}
                                                        />
                                                    </linearGradient>
                                                </defs>
                                                <XAxis dataKey="name"/>
                                                <YAxis
                                                    tickFormatter={(value) =>
                                                        new Intl.NumberFormat("en", {
                                                            notation: "compact",
                                                            compactDisplay: "short",
                                                        }).format(value)
                                                    }
                                                />
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <Tooltip
                                                    formatter={(value) =>
                                                        new Intl.NumberFormat("en").format(value)
                                                    }
                                                />
                                                <Area
                                                    type="monotone"
                                                    dataKey="Income"
                                                    label={"Income"}
                                                    stroke="#8884d8"
                                                    fillOpacity={1}
                                                    fill="url(#colorIncome)"
                                                />
                                                <Area
                                                    type="monotone"
                                                    dataKey="Expense"
                                                    label={"Expense"}
                                                    stroke="#82ca9d"
                                                    fillOpacity={1}
                                                    fill="url(#colorExpnese)"
                                                />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                            {/* table*/}
                            {/* <Widget title="Bank Reconciliation Summary ">
                <div className="row">
                  <div className=" table-responsive">
                    <table className="table table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>Account</th>
                          <th> Account Balance </th>
                          <th>Last Reconcile Balance</th>
                          <th>Last Reconcile Date</th>
                          <th> Bank Balance</th>
                          <th>Balance as of</th>
                          <th>Items to Match</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.bankReconcile.map((items, index) => {
                          return (
                            <tr>
                              <td>{items.name}</td>
                              <td>
                                {CisUI().getCurrencyFormated1(items.balance)}{" "}
                              </td>
                              <td>
                                {CisUI().getCurrencyFormated1(
                                  items.lastBalance
                                )}
                              </td>
                              <td>
                                {items.lastDate !== ""
                                  ? CisUI().DateFormat(items.lastDate)
                                  : ""}
                              </td>
                              <td>
                                {CisUI().getCurrencyFormated1(
                                  items.bankBalance
                                )}
                              </td>
                              <td>
                                {items.balanceDate !== ""
                                  ? CisUI().DateFormat(items.balanceDate)
                                  : ""}
                              </td>
                              <td>
                                {CisUI().getCurrencyFormated1(
                                  items.itemToMatch
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Widget> */}
                            <div className="col-md-12">
                                <Widget styleName={`gx-card-full gx-p-3 gx-bg-color`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-mr-2 gx-mr-xxl-3">
                                            <i className={`icon icon-tasks gx-fs-icon-lg`}/>
                                        </div>
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1">
                                                {CisUI().getCurrencyFormated1(state.cashOnHand)}
                                            </h4>
                                            <p className="gx-mb-0">CASH EQU. BALANCE</p>
                                        </div>
                                    </div>
                                </Widget>
                            </div>
                            <div className="col-md-12">
                                <Widget styleName={`gx-card-full gx-p-3 gx-bg-color`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-mr-2 gx-mr-xxl-3">
                                            <i className={`icon icon-tasks gx-fs-icon-lg`}/>
                                        </div>
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1">
                                                {CisUI().getCurrencyFormated1(state.getTotalExpenses)}
                                            </h4>
                                            <p className="gx-mb-0">TOTAL EXPENSES</p>
                                        </div>
                                    </div>
                                </Widget>
                            </div>

                            <div className="col-md-12" style={{overflow: 'auto'}}>
                                <Widget styleName={`gx-card-full gx-bg-color`}>
                                    <div className="gx-customers">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th><IntlMessages id={"Title"} /></th>
                                                <th className="currency"><IntlMessages id={"Previous Month"} /></th>
                                                <th className="currency"><IntlMessages id={"Current Month"} /></th>
                                                <th className="currency"><IntlMessages id={"Differences"} /></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Net Tithe and Offering</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.tithe)}</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.tithe)}</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.tithe - state.currentMonthCollection.tithe)}</td>
                                            </tr>
                                            <tr>
                                                <td>Add: Missions Offering</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.mission)}</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.mission)}</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.mission - state.currentMonthCollection.mission)}</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.total)}</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.total)}</td>
                                                <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.total - state.currentMonthCollection.total)}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Widget>
                            </div>

                            <div className="col-md-12" style={{overflow: 'auto'}}>
                                <Metrics title={<IntlMessages id={"Total Gross Tithe"} />}>
                                    <Row>
                                        <Col xl={11} lg={12} md={24} sm={12} xs={12}>
                                            <h4 className="gx-mb-1">{CisUI().getCurrencyFormated1(state.totalTithe)}</h4>
                                            <p className="gx-mb-md-0 gx-text-light"><IntlMessages id={"Total Tithes YTD"} /></p>
                                        </Col>
                                        <Col xl={13} lg={12} md={24} sm={12} xs={12}>

                                            <ResponsiveContainer className="gx-barchart" width="100%" height={70}>
                                                <BarChart data={state.titheCompare}
                                                          margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                                                    <Bar dataKey="lastYear" stackId="a" fill="#27496d" barSize={width <= 575 ? 4 : 8}/>
                                                    <Bar dataKey="thisYear" stackId="a" fill="#FE9E15" barSize={width <= 575 ? 4 : 8}/>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Col>
                                    </Row>
                                </Metrics>
                            </div>

                            <div className="col-md-12" style={{overflow: 'auto'}}>
                                <Metrics styleName={`gx-card-full`} title="This year : All Donations">
                                    <Row>
                                        <Col lg={24} md={24} sm={15} xs={15}>
                                            <ResponsiveContainer width="100%" height={103}>
                                                <AreaChart data={state.thisyearDonation}
                                                           margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                                    <defs>
                                                        <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                                                            <stop offset="5%" stopColor="#27496d" stopOpacity={0.9}/>
                                                            <stop offset="95%" stopColor="#00a8cc" stopOpacity={0.9}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <XAxis dataKey="name" />
                                                    <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short" }).format(value)} />
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Tooltip formatter={(value) => CisUI().getCurrencyFormated1(value)} />
                                                    <Area type="monotone" dataKey='total' strokeWidth={0} stackId="2" stroke='#867AE5' fill="url(#color1)" fillOpacity={1}/>
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        </Col>
                                    </Row>
                                </Metrics>
                            </div>


                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="s_card">
                                        <div className="s_card_title">
                                            Current Year Budget by Department
                                        </div>
                                        {/*<TwoSimplePieChart/>*/}
                                        <ResponsiveContainer width="100%" height={300}>
                                            <BarChart
                                                data={state.getBudgetByDepartment}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="income" label="Income" fill="#1F618D" />
                                                <Bar dataKey="expense" label="Expense" fill="#3DA0E2" />
                                            </BarChart>
                                        </ResponsiveContainer>

                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="s_card">
                                        <div className="s_card_title">
                                            Tithes and Expenses
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    {
                                                        jsonfile.listView.hq_title.map((items,index)=>{
                                                            return <th>{items}</th>
                                                        })
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    state.getTitheReport.map((items,index)=>{
                                                        if(items.fl_type === 'total') {
                                                            return <tr>
                                                                <th>{items["month"]}</th>
                                                                <th style={{textAlign: 'right'}} >{CisUI().getCurrencyFormated1(items.gross_tithe)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ldf)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ddf)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.adf)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.local_expense)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.district_expense)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.area_expense)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.sal_allowance)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.medicals)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total_deduction)}</th>
                                                                <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.balance_deposited)}</th>
                                                            </tr>
                                                        }
                                                        else {
                                                            return <tr>
                                                                <td>{items["month"]}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.gross_tithe)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ldf)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ddf)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.adf)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.local_expense)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.district_expense)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.area_expense)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.sal_allowance)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.medicals)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total_deduction)}</td>
                                                                <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.balance_deposited)}</td>
                                                            </tr>
                                                        }
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="s_card">
                                        <div className="s_card_title">Last 10 Vouchers</div>
                                        <div className="s_card_body">
                                            <table className="table table-hover invoice_table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Voucher#</th>
                                                    <th scope="col">Account</th>
                                                    <th scope="col">Doc Class</th>
                                                    <th scope="col">Receipt</th>
                                                    <th scope="col">Payment</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    state.getVouchers.map((items,index)=>{
                                                        return <tr>
                                                            <td>{CisUI().DateFormat(items.date)}</td>
                                                            <td>{items.voucher_no}</td>
                                                            <td>{items.title}</td>
                                                            <td>{items.doc_class_name}</td>
                                                            <td>{CisUI().getCurrencyFormated1(items.debit)}</td>
                                                            <td>{CisUI().getCurrencyFormated1(items.credit)}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="s_card">
                                        <div className="s_card_title">
                                            PURCHASE AND SALES HISTORY
                                        </div>
                                        <div className="s_card_body">
                                            {/*<Stacked3DColumnChart />*/}
                                            {/*{Stacked3DColumnChart(state.PurchaseAndSales)}*/}
                                            <ResponsiveContainer width="100%" height={300}>
                                                <BarChart
                                                    data={state.PurchaseAndSales}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="month" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="purchase" label="Purchase" fill="#1F618D" />
                                                    <Bar dataKey="sales" label="Sales" fill="#3DA0E2" />
                                                </BarChart>
                                            </ResponsiveContainer>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{display : 'none'}}>
                                    <div className="s_card">
                                        {/*<CombinedBullet/>*/}
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <Widget title="Current Financial Status">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <Metrics styleName={`gx-card-full gx-bg-white`}>
                                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                                        <div className="gx-media-body">
                                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.cashBalance)}</h4>
                                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Cash Equ. Balance</p>
                                                        </div>
                                                    </div>
                                                </Metrics>
                                            </div>
                                            <div className="col-md-3">
                                                <Metrics styleName={`gx-card-full gx-bg-white`}>
                                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                                        <div className="gx-media-body">
                                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.exenseYT)}</h4>
                                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Total Expenses</p>
                                                        </div>
                                                    </div>
                                                </Metrics>
                                            </div>
                                            <div className="col-md-3">
                                                <Metrics styleName={`gx-card-full gx-bg-white`}>
                                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                                        <div className="gx-media-body">
                                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.receiveableBalance)}</h4>
                                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Act. Receivable</p>
                                                        </div>
                                                    </div>
                                                </Metrics>
                                            </div>
                                            <div className="col-md-3">
                                                <Metrics styleName={`gx-card-full gx-bg-white`}>
                                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                                        <div className="gx-media-body">
                                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.payableBalance)}</h4>
                                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Act. Payable</p>
                                                        </div>
                                                    </div>
                                                </Metrics>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th><IntlMessages id={"Indicator"} /></th>
                                                        <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Current Month"} /></th>
                                                        <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Previous Month"} /></th>
                                                        <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Change"} /></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        state.kpi.map((items,index) => {
                                                            return <tr>
                                                                <td>{items.name}</td>
                                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>
                                                                    {
                                                                        items.current > items.last ? <i style={{color : 'green'}} className="fas fa-caret-up" /> : items.change < items.last ? <i style={{color : 'red'}} className="fas fa-caret-down" /> : ""
                                                                    }
                                                                    {items.current}</td>
                                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>{items.last}</td>
                                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>
                                                                    {
                                                                        items.change > 0 ? <i style={{color : 'green'}} className="fas fa-caret-up" /> : items.change < 0 ? <i style={{color : 'red'}} className="fas fa-caret-down" /> : ""
                                                                    }
                                                                    {items.change}
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Widget>
                                </div>


                                {/* <div className="col-md-5">
                  <Metrics title={<IntlMessages id={"Total Gross Tithe"} />}>
                    <Row>
                      <Col xl={11} lg={12} md={24} sm={12} xs={12}>
                        <h4 className="gx-mb-1">
                          {CisUI().getCurrencyFormated1(state.totalTithe)}
                        </h4>
                        <p className="gx-mb-md-0 gx-text-light">
                          <IntlMessages id={"Total Tithes YTD"} />
                        </p>
                      </Col>
                      <Col xl={13} lg={12} md={24} sm={12} xs={12}>
                        <ResponsiveContainer
                          className="gx-barchart"
                          width="100%"
                          height={70}
                        >
                          <BarChart
                            data={state.titheCompare}
                            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <Tooltip
                              formatter={(value) =>
                                new Intl.NumberFormat("en").format(value)
                              }
                            />
                            <Bar
                              dataKey="lastYear"
                              stackId="a"
                              fill="#27496d"
                              barSize={width <= 575 ? 4 : 8}
                            />
                            <Bar
                              dataKey="thisYear"
                              stackId="a"
                              fill="#FE9E15"
                              barSize={width <= 575 ? 4 : 8}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </Col>
                    </Row>
                  </Metrics>
                </div> */}
                                {/* <div className="col-md-7">
                  {" "}
                  <Metrics
                    styleName={`gx-card-full`}
                    title="This year : All Donations"
                  >
                    <Row>
                      <Col lg={24} md={24} sm={15} xs={15}>
                        <ResponsiveContainer width="100%" height={103}>
                          <AreaChart
                            data={state.thisyearDonation}
                            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                          >
                            <defs>
                              <linearGradient
                                id="color1"
                                x1="0"
                                y1="0"
                                x2="1"
                                y2="0"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#27496d"
                                  stopOpacity={0.9}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#00a8cc"
                                  stopOpacity={0.9}
                                />
                              </linearGradient>
                            </defs>
                            <XAxis dataKey="name" />
                            <YAxis
                              tickFormatter={(value) =>
                                new Intl.NumberFormat("en", {
                                  notation: "compact",
                                  compactDisplay: "short",
                                }).format(value)
                              }
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip
                              formatter={(value) =>
                                CisUI().getCurrencyFormated1(value)
                              }
                            />
                            <Area
                              type="monotone"
                              dataKey="total"
                              strokeWidth={0}
                              stackId="2"
                              stroke="#867AE5"
                              fill="url(#color1)"
                              fillOpacity={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </Col>
                    </Row>
                  </Metrics>
                </div> */}
                                {/* <div className="col-md-12">
                  <Metrics>
                    <div className="gx-customers table-responsive">
                      <table className="table table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <th>
                              <IntlMessages id={"Title"} />
                            </th>
                            <th className="currency">
                              <IntlMessages id={"Previous Month"} />
                            </th>
                            <th className="currency">
                              <IntlMessages id={"Current Month"} />
                            </th>
                            <th className="currency">
                              <IntlMessages id={"Differences"} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Net Tithe and Offering</td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.previousMonthCollection.tithe
                              )}
                            </td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.currentMonthCollection.titheCombinedBullet
                              )}
                            </td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.previousMonthCollection.tithe -
                                  state.currentMonthCollection.tithe
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Add: Missions Offering</td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.previousMonthCollection.mission
                              )}
                            </td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.currentMonthCollection.mission
                              )}
                            </td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.previousMonthCollection.mission -
                                  state.currentMonthCollection.mission
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Add: Other Contributions</td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.previousMonthCollection.others
                              )}
                            </td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.currentMonthCollection.others
                              )}
                            </td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.previousMonthCollection.others -
                                  state.currentMonthCollection.others
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.previousMonthCollection.total
                              )}
                            </td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.currentMonthCollection.total
                              )}
                            </td>
                            <td className="currency">
                              {CisUI().getCurrencyFormated1(
                                state.previousMonthCollection.total -
                                  state.currentMonthCollection.total
                              )}
                            </td>
                          </tr>
                        </tbody><PieChart3D/>
                      </table>
                    </div>
                  </Metrics>
                </div> */}
                            </div>
                        </div>
                    </div>
                </Col>

                <Col span={24}>
                    <Row>
                        <Col xl={8} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2">
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap" style={{height : '60px'}}>
                                            <div className="gx-media-body">
                                                <h4 className="gx-fs-xl text-center gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}} >{CisUI().getCurrencyFormated1(state.highestTithe.amount)}</h4>
                                                <p className="gx-mb-0 text-center" style={{color: '#27496d'}}>{
                                                    CisUI().getUserInfo('comarea') === 6 ? "Net Income " : "Highest Net Tithe "
                                                }
                                                    {/*{state.highestTithe.name}*/}
                                                </p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>

                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap" style={{height : '60px'}}>
                                            <div className="gx-media-body">
                                                <p>Highest Missions Offering</p>
                                                <h4 className="gx-fs-xl text-center gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}} >{CisUI().getCurrencyFormated1(state.highestMission.amount)}</h4>
                                                <p className="text-center gx-mb-0" style={{color: '#27496d'}}>{state.highestMission.name}</p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>
                            </Row>
                        </Col>

                        <Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap" style={{height : '60px'}}>
                                            <div className="gx-mr-2 gx-mr-xxl-3">
                                                <i className={`icon icon-diamond gx-fs-icon-lg`} style={{color: '#27496d'}}/>
                                            </div>
                                            <div className="gx-media-body">
                                                <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}} >{CisUI().getCurrencyFormated1(state.grossTithe)}</h4>
                                                <p className="gx-mb-0" style={{color: '#27496d'}}>
                                                    {
                                                        CisUI().getUserInfo('comarea') <= 3 ? "Tithe Collected" : CisUI().getUserInfo('comarea') === 4 ? "Gross Tithe from District" : CisUI().getUserInfo('comarea') === 5 ? "Gross Tithe From Local" : CisUI().getUserInfo('comarea') === 6 ? "Gross Tithe" : ""
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap" style={{height : '60px'}}>
                                            <div className="gx-mr-2 gx-mr-xxl-3">
                                                <i className={`icon icon-tasks gx-fs-icon-lg`} style={{color: '#27496d'}} />
                                            </div>
                                            <div className="gx-media-body">
                                                <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}} >{CisUI().getCurrencyFormated1(state.allowableExpense)}</h4>
                                                <p className="gx-mb-0" style={{color: '#27496d'}} >Allowable Expenses</p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap" style={{height : '60px'}}>
                                            <div className="gx-mr-2 gx-mr-xxl-3">
                                                <i className={`icon icon-team gx-fs-icon-lg`} style={{color: '#27496d'}} />
                                            </div>
                                            <div className="gx-media-body">
                                                <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1 " style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.netTithe)}</h4>
                                                <p className="gx-mb-0" style={{color: '#27496d'}}>
                                                    {
                                                        CisUI().getUserInfo('comarea') <= 3 ? "Net Tithe From Region" : CisUI().getUserInfo('comarea') === 4 ? "Net to Nation" : CisUI().getUserInfo('comarea') === 5 ? "Net to Region" : CisUI().getUserInfo('comarea') === 6 ? "Net to District" : ""
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap" style={{height : '60px'}}>
                                            <div className="gx-mr-2 gx-mr-xxl-3">
                                                <i className={`icon icon-files gx-fs-icon-lg`} style={{color: '#27496d'}} />
                                            </div>
                                            <div className="gx-media-body">
                                                <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.devFund)}</h4>
                                                <p className="gx-mb-0" style={{color: '#27496d'}}>{state.devFundTitle}</p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Widget title="Current Financial Status">
                        <div className="row">
                            <div className="col-md-3">
                                <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.cashBalance)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Cash Equ. Balance</p>
                                        </div>
                                    </div>
                                </Widget>
                            </div>
                            <div className="col-md-3">
                                <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.exenseYT)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Total Expenses</p>
                                        </div>
                                    </div>
                                </Widget>
                            </div>
                            <div className="col-md-3">
                                <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.receiveableBalance)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Accounts Receivable</p>
                                        </div>
                                    </div>
                                </Widget>
                            </div>
                            <div className="col-md-3">
                                <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.payableBalance)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Accounts Payable</p>
                                        </div>
                                    </div>
                                </Widget>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th><IntlMessages id={"Indicator"} /></th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Current Month"} /></th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Previous Month"} /></th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Change"} /></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        state.kpi.map((items,index) => {
                                            return <tr>
                                                <td>{items.name}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>
                                                    {
                                                        items.current > items.last ? <i style={{color : 'green'}} className="fas fa-caret-up" /> : items.change < items.last ? <i style={{color : 'red'}} className="fas fa-caret-down" /> : ""
                                                    }
                                                    {items.current}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>{items.last}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>
                                                    {
                                                        items.change > 0 ? <i style={{color : 'green'}} className="fas fa-caret-up" /> : items.change < 0 ? <i style={{color : 'red'}} className="fas fa-caret-down" /> : ""
                                                    }
                                                    {items.change}
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Widget>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Widget title="Bank Reconciliation Summary">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Account</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Account Balance</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Last Reconcile Balance</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Last Reconcile Date</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Bank Balance</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Balance as of</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Items to Match</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        state.bankReconcile.map((items,index) => {
                                            return <tr>
                                                <td>{items.name}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>{CisUI().getCurrencyFormated1(items.balance)}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>{CisUI().getCurrencyFormated1(items.lastBalance)}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>{items.lastDate !== "" ? CisUI().DateFormat(items.lastDate) : ""}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>{CisUI().getCurrencyFormated1(items.bankBalance)}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>{items.balanceDate !== '' ? CisUI().DateFormat(items.balanceDate): ""}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>{CisUI().getCurrencyFormated1(items.itemToMatch)}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Widget>
                </Col>

                <Col span={24} style={{display : 'none'}}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <ChartCard
                                        prize="GH₵ 9,626"
                                        title="23"
                                        icon="bitcoin"
                                        children={
                                            <ResponsiveContainer width="100%" height={75}>
                                                <AreaChart
                                                    data={increamentData}
                                                    margin={{top: 0, right: 0, left: 0, bottom: 0}}
                                                >
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient
                                                            id="color3"
                                                            x1="0"
                                                            y1="0"
                                                            x2="1"
                                                            y2="0"
                                                        >
                                                            <stop
                                                                offset="25%"
                                                                stopColor="#0a5a66"
                                                                stopOpacity={0.99}
                                                            />
                                                            <stop
                                                                offset="75%"
                                                                stopColor="#13c2c2"
                                                                stopOpacity={0.99}
                                                            />
                                                        </linearGradient>
                                                    </defs>
                                                    <Area
                                                        dataKey="price"
                                                        strokeWidth={0}
                                                        stackId="2"
                                                        stroke="#020f3e"
                                                        fill="url(#color3)"
                                                        fillOpacity={1}
                                                    />
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        }
                                        styleName="up"
                                        desc="Total Income"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <ChartCard
                                        prize="GH₵ 7,831"
                                        title="07"
                                        icon="etherium"
                                        children={
                                            <ResponsiveContainer width="100%" height={75}>
                                                <AreaChart
                                                    data={increamentData}
                                                    margin={{top: 0, right: 0, left: 0, bottom: 0}}
                                                >
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient
                                                            id="color4"
                                                            x1="0"
                                                            y1="0"
                                                            x2="1"
                                                            y2="0"
                                                        >
                                                            <stop
                                                                offset="5%"
                                                                stopColor="#13c2c2"
                                                                stopOpacity={0.9}
                                                            />
                                                            <stop
                                                                offset="95%"
                                                                stopColor="#1d3668"
                                                                stopOpacity={0.9}
                                                            />
                                                        </linearGradient>
                                                    </defs>
                                                    <Area
                                                        dataKey="price"
                                                        type="monotone"
                                                        strokeWidth={0}
                                                        stackId="2"
                                                        stroke="#4D95F3"
                                                        fill="url(#color4)"
                                                        fillOpacity={1}
                                                    />
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        }
                                        styleName="up"
                                        desc="Total Expense"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <ChartCard
                                        prize="GH₵ 1,239"
                                        title="08"
                                        icon="ripple"
                                        children={
                                            <ResponsiveContainer width="100%" height={75}>
                                                <AreaChart
                                                    data={increamentData}
                                                    margin={{top: 0, right: 0, left: 0, bottom: 0}}
                                                >
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient
                                                            id="color5"
                                                            x1="0"
                                                            y1="0"
                                                            x2="0"
                                                            y2="1"
                                                        >
                                                            <stop
                                                                offset="5%"
                                                                stopColor="#1d3668"
                                                                stopOpacity={0.8}
                                                            />
                                                            <stop
                                                                offset="95%"
                                                                stopColor="#13c2c2"
                                                                stopOpacity={0.8}
                                                            />
                                                        </linearGradient>
                                                    </defs>
                                                    <Area
                                                        dataKey="price"
                                                        strokeWidth={0}
                                                        stackId="2"
                                                        stroke="#13c2c2"
                                                        fill="url(#color5)"
                                                        fillOpacity={1}
                                                    />
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        }
                                        styleName="down"
                                        desc="Purchase"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <ChartCard
                                        prize="GH₵ 8,490"
                                        title="47"
                                        icon="litcoin"
                                        children={
                                            <ResponsiveContainer width="100%" height={75}>
                                                <LineChart
                                                    data={lineData}
                                                    margin={{top: 5, right: 5, left: 5, bottom: 5}}
                                                >
                                                    <Tooltip/>
                                                    <Line
                                                        dataKey="price"
                                                        stroke="#1d3668"
                                                        dot={{stroke: "#13c2c2", strokeWidth: 2}}
                                                    />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }
                                        styleName="down"
                                        desc="Sales"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="s_card">
                                <div className="s_card_title">Income and Expenses - Missions</div>
                                {/*<PieChart3D  />*/}
                                {PieChart3D([])}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24} style={{display : 'none'}}>
                    <div className="row">
                        <div className="col-md-5">

                            <Portfolio/>
                        </div>
                        <div className="col-md-5">
                            <BalanceHistory/>
                        </div>

                        <div className="col-md-2">
                            <div className="s_card">
                                <Column3DChart/>
                            </div>
                        </div>

                    </div>
                </Col>
                <Col span={24} style={{display : 'none'}}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="s_card">
                                        {/*<DashedLineChart/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="s_card">
                                        {/*<PercentAreaChart/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24} style={{display : 'none'}}>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="s_card">
                                <div className="s_card_title">14-total sales</div>
                                <div className="s_card_body">
                                    <DonutChart3D/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="s_card ">
                                <div className="s_card_title">15-total sales</div>
                                <div className="s_card_body">

                                    <BarChartStackedBySign/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="s_card">
                                <div className="s_card_title">16-total sales</div>
                                <div className="s_card_body">
                                    <ChartWithGapsInData/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col span={24} style={{display : 'none'}}>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="s_card">
                                <div className="s_card_title">12otal sales</div>
                                <div className="s_card_body">
                                    <FillBetweenTwoLines/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="s_card">
                                        <div className="s_card_title">34total sales</div>
                                        <div className="s_card_body">
                                            <DateBasedLine/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="s_card">
                                        <div className="s_card_title">t56otal sales</div>

                                        <CustomActiveShapePieChart/>

                                        <hr/>
                                        <CustomActiveShapePieChart2/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row style={{display : 'none'}}>
                <Col span={24}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="s_card">
                                <div className="s_card_title">Top Performer Help Agents</div>
                                <div className="s_membar_card">
                                    <div className="s_single_membar">
                                        <div className="personal_info">
                                            <img
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTenrimrJDjSQlFjW3ZWYKY8tQMJHNCio4yGdBsIPMknoHWMBlyTqlozKfOnEeZLJh3fZo&usqp=CAU"
                                                alt="man"/>
                                            <div className="name_id">
                                                <div className="name"> Socrates Itumay</div>
                                                <div className="id"> Agent ID: 12022</div>
                                            </div>
                                        </div>
                                        <div className="gole">
                                            <div className="getpoint"> 90/100</div>
                                            <div className="status"> Reached Goal</div>
                                        </div>
                                    </div>
                                    <div className="s_single_membar">
                                        <div className="personal_info">
                                            <img
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTenrimrJDjSQlFjW3ZWYKY8tQMJHNCio4yGdBsIPMknoHWMBlyTqlozKfOnEeZLJh3fZo&usqp=CAU"
                                                alt="man"/>
                                            <div className="name_id">
                                                <div className="name"> Socrates Itumay</div>
                                                <div className="id"> Agent ID: 12022</div>
                                            </div>
                                        </div>
                                        <div className="gole">
                                            <div className="getpoint"> 90/100</div>
                                            <div className="status"> Reached Goal</div>
                                        </div>
                                    </div>
                                    <div className="s_single_membar">
                                        <div className="personal_info">
                                            <img
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTenrimrJDjSQlFjW3ZWYKY8tQMJHNCio4yGdBsIPMknoHWMBlyTqlozKfOnEeZLJh3fZo&usqp=CAU"
                                                alt="man"/>
                                            <div className="name_id">
                                                <div className="name"> Socrates Itumay</div>
                                                <div className="id"> Agent ID: 12022</div>
                                            </div>
                                        </div>
                                        <div className="gole">
                                            <div className="getpoint"> 90/100</div>
                                            <div className="status"> Reached Goal</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Col>
            </Row>
        </Auxiliary>
    );
};

export default AdminDashboard;
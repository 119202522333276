import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS, HIDE_MESSAGE,
    INIT_URL,
    SIGNOUT_USER_SUCCESS, UPDATE_LOAD_USER,
    USER_DATA, USER_PROFILE_DATA,
    USER_TOKEN_SET,
    FORGOT_PASSWORD, RESET_PASSWORD
} from "../../constants/ActionTypes";
import axios from '../../util/Api'
import {CisUI} from "../../util/CISUI";
import {notification} from 'antd';

import  slocalStorage  from  "react-secure-storage";

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const userSignUp = ({email, password, name}) => {
    console.log(email, password);
    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('auth/register', {
                email: email,
                password: password,
                name: name
            }
        ).then(({data}) => {
            console.log("data:", data);
            if (data.result) {
                slocalStorage.setItem("token", data.token.access_token);
                localStorage.setItem("token", data.token.access_token);
                axios.defaults.headers.common['authorization'] = "Bearer " + data.token.access_token;
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
                dispatch({type: USER_DATA, payload: data.user});
            } else {
                console.log("payload: data.error", data.error);
                dispatch({type: FETCH_ERROR, payload: "Network Error"});
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const userSignInVerify = ({email, password}) => {
    // const history = useHistory();

    return (dispatch) => {
        dispatch({type: FETCH_START});

        const userLocal = slocalStorage.getItem('dupdata') || "";
        // const JSD = JSON.stringify(userLocal);
        const pr = JSON.parse(userLocal);
        // console.log("USERID:"+ pr['userid']);
        axios.post('login', pr
        ).then(({data}) => {
            console.log("userSignIn: ", data);
            if (data.result) {
                slocalStorage.setItem("token", data.token.access_token);
                localStorage.setItem("token", data.token.access_token);

                const userData = {
                    "firstname": data.user.firstname,
                    "lastname": data.user.lastname,
                    "name": data.user.name,
                    "phone": data.user.phone,
                    "email": data.user.email,
                    "status": data.user.status,
                    "image": data.user.image,
                    "remember_token": data.user.remember_token,
                    "act_code_show": data.user.act_code_show,
                    "companyName": data.user.companyName,
                    "company": data.user.companyName,
                    "comarea": data.user.comarea,
                    "company_email": data.user.company_email,
                    "company_address": data.user.company_address,
                    "company_phone": data.user.phone,
                    "region_id": data.user.region_id,
                    "district_id": data.user.district_id,
                    "local_id": data.user.local_id
                }

                localStorage.setItem("userData", JSON.stringify(userData));
                slocalStorage.setItem("userData",JSON.stringify(data.user));
                slocalStorage.setItem("otpstatus",1);
                slocalStorage.removeItem("dupdata");
                axios.defaults.headers.common['Authorization'] = data.token.access_token;
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
                dispatch(getUser(data.token.access_token));
                window.location = "/main/dashboard";
            } else {
                CisUI().showAlert(data.error,"failed",data.error);
                dispatch({type: HIDE_MESSAGE, payload: data.error});
                // dispatch({type: FETCH_ERROR, payload: data.error});
            }
        }).catch(function (error) {
            CisUI().showAlert(error,"failed",error.message);
            dispatch({type: HIDE_MESSAGE, payload: error});
            // dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const userSignIn = ({email, password}) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        const data = {
            userid: email,
            password: password,
        };
        // console.log("Headers:" +JSON.stringify(axios.defaults.headers));
        axios.post('login', data, {}
        ).then(({data}) => {
            // console.log("userSignIn: ", data);
            if (data.result) {
                slocalStorage.setItem("token", data.token.access_token);
                localStorage.setItem("token", data.token.access_token);

                const userData = {
                    "firstname": data.user.firstname,
                    "lastname": data.user.lastname,
                    "name": data.user.name,
                    "phone": data.user.phone,
                    "email": data.user.email,
                    "status": data.user.status,
                    "image": data.user.image,
                    "remember_token": data.user.remember_token,
                    "act_code_show": data.user.act_code_show,
                    "companyName": data.user.companyName,
                    "company": data.user.companyName,
                    "company_email": data.user.company_email,
                    "company_address": data.user.company_address,
                    "company_phone": data.user.phone,
                    "comarea": data.user.comarea,
                    "region_id": data.user.region_id,
                    "district_id": data.user.district_id,
                    "local_id": data.user.local_id
                }

                localStorage.setItem("userData", JSON.stringify(userData));

                slocalStorage.setItem("userData",JSON.stringify(data.user));
                slocalStorage.setItem("otpstatus",1);
                slocalStorage.removeItem("dupdata");
                axios.defaults.headers.common['Authorization'] = data.token.access_token;
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
                dispatch(getUser(data.token.access_token));
                window.location.reload();
            } else {

                if(data.retry === 1) {
                    const ndata = {
                        userid: email,
                        password: password,
                        retry: 1
                    };
                    slocalStorage.setItem("dupdata", JSON.stringify(ndata));
                    dispatch({type: FETCH_SUCCESS, payload: data.error});
                    // history.push("/signinverify");
                    // dispatch({type: HIDE_MESSAGE, payload: data.error});
                    window.location = "/signinverify";
                }
                else {
                    CisUI().showAlert(data.error,"failed",data.error);
                    // dispatch({type: HIDE_MESSAGE, payload: data.error});
                    dispatch({type: FETCH_SUCCESS, payload: data.error});
                }

               // dispatch({type: HIDE_MESSAGE, payload: data.error});
               // dispatch({type: FETCH_ERROR, payload: data.error});
               //  CisUI().showAlert(data.error,"failed",data.error)
            }
        }).catch(function (error) {
            // dispatch({type: FETCH_ERROR, payload: error.message});
            // dispatch({type: HIDE_MESSAGE, payload: error.error});
            // dispatch({type: FETCH_ERROR, payload: data.error});
            CisUI().showAlert(error.message,"failed",error.message);
            // dispatch({type: HIDE_MESSAGE, payload: data.error});
            dispatch({type: FETCH_SUCCESS, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const forgotPassword = ({email}) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        const data = {
            email: email,
        };
        axios.post('forgotpassword', data, {}
        ).then(({data}) => {
            // console.log("userSignIn: ", data);
            if (data.result) {
                localStorage.setItem("fuser", email);
                CisUI().showAlert("Success","success",data.msg);
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: FORGOT_PASSWORD, payload: true});
            } else {
                CisUI().showAlert("Error","failed",data.msg);
                dispatch({type: FETCH_SUCCESS, payload: data.msg});
                dispatch({type: FORGOT_PASSWORD, payload: false});
            }
        }).catch(function (error) {
            CisUI().showAlert(error.message,"failed",error.message);
            dispatch({type: FETCH_SUCCESS, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const ResetPassword = ({email, otp, new_password, confirm_password}) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        const data = {
            email: email,
            otp: otp,
            new_password: new_password,
            confirm_password: confirm_password
        };
        axios.post('passwordreset', data, {}
        ).then(({data}) => {
            // console.log("userSignIn: ", data);
            if (data.result) {
                localStorage.removeItem("fuser");
                CisUI().showAlert("Success","success",data.msg);
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: RESET_PASSWORD, payload: true});
            } else {
                CisUI().showAlert("Error","failed",data.msg);
                dispatch({type: FETCH_SUCCESS, payload: data.msg});
                dispatch({type: RESET_PASSWORD, payload: false});
            }
        }).catch(function (error) {
            CisUI().showAlert(error.message,"failed",error.message);
            dispatch({type: FETCH_SUCCESS, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};



export const getUser = (token) => {
    return (dispatch) => {
        if (!token) {
            token = slocalStorage.getItem('token');
        }

        axios.defaults.headers.common['Authorization'] = token;

        dispatch({type: FETCH_START});
        //let uinputs = { token: token };
        axios.post('authuser',null,CisUI().HeaderRequest).then(({data}) => {
            // console.log("userSignIn: ", data);
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: USER_DATA, payload: data.user});
                slocalStorage.setItem("prm",JSON.stringify(data.permission));
                slocalStorage.setItem("titheID",data.titheID);
                //console.log("UserInfo :"+data.user.name);
            } else {
                dispatch({type: FETCH_ERROR, payload: data.error});
            }
        }).catch(function (error) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: UPDATE_LOAD_USER, payload: false});
            // if(localStorage.getItem('userData')){
            //     localStorage.clear()
            //     slocalStorage.clear()
            // }
            // console.log("Error****:", error.message);
        });
    }
};

export const getUserProfileData = (id) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.get('showmyaccount/'+id)
            .then(res => {
                if (res.data.status === 1) {
                    // console.log("getUserProfileData: ", res.data.data);
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: USER_PROFILE_DATA, payload: res.data.data});
                    //console.log("UserInfo :"+data.user.name);
                } else {
                    dispatch({type: FETCH_ERROR, payload: res.data.error});
                }
            })
            .catch(error => {
                console.log("Error****:", error.message);
            });
    }
};

export const updateProfile = (values, id) => {
    return (dispatch) => {
        document.getElementById("loader").style.display = "block";
        axios.post('updatemyaccount/'+id, values)
            .then(res => {
                if (res.data.status === 1) {
                    // console.log("updateProfile: ", res.data);
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: USER_PROFILE_DATA, payload: res.data.data});
                    slocalStorage.setItem("userData",JSON.stringify(res.data.data));
                    // localStorage.setItem("userData",JSON.stringify(res.data.data));

                    const user = res.data.data;
                    const userData = {
                        "firstname": user.firstname,
                        "lastname": user.lastname,
                        "name": user.name,
                        "phone": user.phone,
                        "email": user.email,
                        "status": user.status,
                        "image": user.image,
                        "remember_token": user.remember_token,
                        "act_code_show": user.act_code_show,
                        "companyName": user.companyName,
                        "company": user.companyName,
                        "comarea": user.comarea,
                        "region_id": user.region_id,
                        "district_id": user.district_id,
                        "local_id": user.local_id
                    }

                    localStorage.setItem("userData", JSON.stringify(userData));

                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });

                    window.location.reload();

                } else {
                    dispatch({type: FETCH_ERROR, payload: res.data.error});
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }

                document.getElementById("loader").style.display = "none";
            })
            .catch(error => {
                console.log("Error****:", error.message);
                document.getElementById("loader").style.display = "none";
            });
    }
}

export const userSignOut = () => {

    return (dispatch) => {
        dispatch({type: FETCH_START});

        axios.post('auth/logout').then(({data}) => {
            console.log("log out",data)
            if (data.result) {
                slocalStorage.removeItem("token");
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({type: FETCH_ERROR, payload: data.error});
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });

        localStorage.removeItem("token");
        slocalStorage.removeItem("token");
        slocalStorage.removeItem("userInfo");
        slocalStorage.removeItem("reconcile");
        slocalStorage.removeItem("userData");
        slocalStorage.removeItem("otpstatus");
        slocalStorage.removeItem("titheID");
        localStorage.removeItem("userData");
        slocalStorage.removeItem("prm");

        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SIGNOUT_USER_SUCCESS});

        //window.location.reload();
    }

};


export const forceLogOut = () => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        if(localStorage.getItem('userData')) {
            localStorage.removeItem("token");
            slocalStorage.removeItem("token");
            slocalStorage.removeItem("userInfo");
            slocalStorage.removeItem("reconcile");
            slocalStorage.removeItem("userData");
            slocalStorage.removeItem("otpstatus");
            slocalStorage.removeItem("titheID");
            localStorage.removeItem("userData");
            slocalStorage.removeItem("prm");
        }
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SIGNOUT_USER_SUCCESS});
    }
};

import React, {useState, useEffect} from "react";
import { Row, Col, Card, notification} from 'antd';
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import {
    MailOutlined,
    MessageOutlined,
    BellOutlined,
    UnorderedListOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined
} from '@ant-design/icons';
import LineIndicator from "../LineIndicator";
import {
    Bar,
    BarChart,
    Area,
    AreaChart,
    Legend,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip,
    LineChart, Line
} from "recharts";
import axios from "../../../../util/Api";
import Config from "../../../../util/config";
import {CisUI, CURRENCY, formatNumber} from "../../../../util/CISUI";
import {Link} from "react-router-dom";
import CanvasJSReact from './../../../canvasjs/canvasjs.react';

import PropTypes from 'prop-types';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import "./newdashboard.css";
import "./dashboard.css";
import {ArrowUpward, ArrowUpwardSharp} from "@material-ui/icons";
import {ReceiptAndPaymentChart} from "./ReceiptAndPaymntChart";
import {CurrentBalance} from "./currentbalance";
import {AccountBalance} from "./AccountBalance";
import {IncomeAndExpendiatures} from "./IncomeAndExpendiature";
import ChartCard from "../../../../components/dashboard/Crypto/ChartCard";


import  localStorage  from  "react-secure-storage";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const FinancialDashboard = (props) => {

    const userLocal = localStorage.getItem('userData') || "";
    let  userID = 0;
    let userName = "";
    let user = {
        id : 0,
        name : ""
    };
    if(userLocal !== "") {
        user = JSON.parse(userLocal);
        userID = user['id'];
        userName = user['name'];
    }

    const [incomeExpense,setInocmeExpnese] = useState([]);
    const [totalCurrentBalance,setTotalCurrentBalance] = useState(0);

    const [stateData,setStateData] = useState({
        transactionState : {
            current : {
                receipt: 239800,
                payment: 189800,
                balance: 79870,
            },
            last : {
                receipt: 209800,
                payment: 159800,
                balance: 59870,
            }
        },
        totalMember : 0,
        totalPayingMember : 0,
        totalNonPayingMember : 0,
        payingPercent : 0,
        nonPayingPercent : 0,
        totalTithe : 0,
        titheCompare : [],
        currentMonthCollection : {
            tithe : 0,
            mission : 0,
            others : 0,
            total : 0
        },
        previousMonthCollection : {
            tithe : 0,
            mission : 0,
            others : 0,
            total : 0
        },
        thisyearDonation : [],
        grossTithe : 0,
        allowableExpense : 0,
        netTithe : 0,
        devFund : 0,
        devFundTitle : "",
        kpi : [],
        bankReconcile : [],
        highestTithe : {
            name : "",
            amount: 0
        },
        highestMission : {
            name : "",
            amount: 0
        },
        titheByRegion : [],
        incomeByRestriction : [],
        cashBalance : 0,
        exenseYT : 0,
        receiveableBalance : 0,
        payableBalance : 0,
        notification : {
            notification: 0,
            bill: 0,
            po: 0,
            task: 0,
        },
    });

    const [state, setState] = useState({
        totalMember: 0,
        totalPayingMember: 0,
        totalNonPayingMember: 0,
        payingPercent: 0,
        nonPayingPercent: 0,
        totalTithe: 0,
        titheCompare: [],
        currentMonthCollection: {
            tithe: 0,
            mission: 0,
            others: 0,
            total: 0,
        },
        previousMonthCollection: {
            tithe: 0,
            mission: 0,
            others: 0,
            total: 0,
        },
        thisyearDonation: [],
        grossTithe: 0,
        allowableExpense: 0,
        netTithe: 0,
        devFund: 0,
        devFundTitle: "",
        kpi: [],
        bankReconcile: [],
        highestTithe: {
            name: "",
            amount: 0,
        },
        highestMission: {
            name: "",
            amount: 0,
        },
        titheByRegion: [],
        incomeByRestriction: [],
        cashBalance: 0,
        exenseYT: 0,
        receiveableBalance: 0,
        payableBalance: 0,
        notification: {
            notification: 0,
            bill: 0,
            po: 0,
            task: 0,
        },
        entryMenu: [],
        voucherIncomeVsPayament: [],
        PurchaseAndSales: [],
        cashOnHand: 0,
        getTotalExpenses: 0,
        getBudgetByDepartment: [],
        getTitheReport: [],
        getVouchers: [],
        allarea: [],
        topperformer: {
            title : "",
            areaName: "",
            data: []
        },
        voucherStatus: {receipt: 0, payment: 0, rp: 0, pp: 0}
    });

    // const departmentExpenses = {
    //     animationEnabled: true,
    //     theme: "light2",
    //     colorSet: "brandColorPlates",
    //     title: {
    //         text: ""
    //     },
    //     axisX: {
    //         title: state.topperformer.areaName,
    //         reversed: false,
    //     },
    //     axisY: {
    //         title: "Previous Month Returns",
    //         includeZero: true,
    //         valueFormatString: "₵######,##0.##",
    //         // valueFormatString: "₵##,##,##,##0.##",
    //         // labelFormatString: "₵##,##,##,###.##",
    //         // labelFormatter: "₵##,##,##,###.##",
    //     },
    //     data: [{
    //         type: "bar",
    //         dataPoints: state.allarea
    //     }]
    // }

    const comArea = CisUI().getUserInfo('comarea');
    const titheTitle = () => {
        if(comArea <= 3){
            return "From Area";
        }
        else if(comArea === 4){
            return "From District";
        }
        else if(comArea === 5){
            return "From Local";
        }
        else {
            return ""
        }
    }

    const [incomesSummary, setIncomesSummary] = useState(
        {
            grossTithes: {
                currentMonth : 0,
                lastMonth: 0,
                lastSixMonth: []
            },
            dExpense: {
                currentMonth : 0,
                lastMonth: 0,
                lastSixMonth: []
            },
            netTithe: {
                currentMonth : 0,
                lastMonth: 0,
                lastSixMonth: []
            },
            missions: {
                currentMonth : 0,
                lastMonth: 0,
                lastSixMonth: []
            },
            incomes : {
                data : [],
                currentMonth : 0,
                lastMonth: 0
            },
            expenses : {
                data : [],
                currentMonth : 0,
                lastMonth: 0
            },
            purchase : {
                data : [],
                currentMonth : 0,
                lastMonth: 0
            },
            sales : {
                data : [],
                currentMonth : 0,
                lastMonth: 0
            },
            ministry : []
        }
    );

    const AccountBalanceData = [
        {name: 'Cash In Hand', value: 100000},
        {name: 'Petty Cash', value: 100000},
        {name: 'Cash In Transit', value: 100000},
        {name: 'Cash At Bank', value: 300000},
        {name: 'CASH AT BANK - FOREIGN', value: 100000},
        {name: 'MOBILE MONEY', value: 100000},
    ];

    useEffect(() => {
        getDashboardData();
        let total = 0;
        AccountBalanceData.map((item,index) => {
            total += item.value;
        });
        setTotalCurrentBalance(total);
    }, []);


    const getDashboardData = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver + "dashboard",null,CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setInocmeExpnese(res.data.incomeExpense);
                    const totalMember = res.data.totalMember
                    const totalPayingMember = res.data.totalPayingMember
                    const totalNonPayingMember = res.data.totalNonPayingMember
                    const payingPercent = ((totalPayingMember * 100) / totalMember).toFixed(2);
                    const nonPayingPercent = ((totalNonPayingMember * 100) / totalMember).toFixed(2);

                    
                    setState({
                        totalMember : totalMember,
                        totalPayingMember : totalPayingMember || 0,
                        payingPercent : payingPercent || 0,
                        totalNonPayingMember : totalNonPayingMember || 0,
                        nonPayingPercent : nonPayingPercent || 0,
                        totalTithe : res.data.totalTithe,
                        titheCompare : res.data.titheCompare,
                        currentMonthCollection : res.data.currentMonthCollection,
                        previousMonthCollection : res.data.previousMonthCollection,
                        thisyearDonation : res.data.thisyearDonation,
                        grossTithe : res.data.grossTithe,
                        allowableExpense : res.data.allowableExpense,
                        netTithe : res.data.netTithe,
                        devFund : res.data.devFund,
                        devFundTitle : res.data.devFundTitle,
                        kpi : res.data.kpi,
                        bankReconcile : res.data.bankReconcile,
                        highestTithe : res.data.highestTithe,
                        highestMission : res.data.highestMission,
                        titheByRegion : res.data.titheByRegion,
                        incomeByRestriction : res.data.incomeByRestriction,
                        exenseYT : res.data.exenseYT,
                        cashBalance : res.data.cashBalance,
                        receiveableBalance : res.data.receiveableBalance,
                        payableBalance : res.data.payableBalance,
                        notification : res.data.notification,
                    });

                     
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                // console.log(errors);
                // document.getElementById("loader").style.display = "none";
            });
    }

    CanvasJS.addColorSet("brandedset",["#2F4F4F","#008080","#2E8B57","#3CB371","#90EE90"]);

    const colorPlates = ["#142850","#27496d","#0c7b93","#00a8cc","#8566aa","#6983aa","#8ec6c5","#4d4c7d","#2c003e","#7f78d2","#1eb2a6"]

    const colors = scaleOrdinal(schemeCategory10).range();

    const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

    const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;

        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
    };

    TriangleBar.propTypes = {
        fill: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    };

    return (
        <div className="dashboard2">
            {CisUI().showLoading}
            <Auxiliary>
                <Row>
                    <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                        <Card>
                            <Row gutter={24}>
                                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                                    <div className="sectionTitle">Receipt & Payment Statistics</div>
                                    <div className="sectionDescription">Last 30 Days</div>
                                    <div style={{paddingTop: '20px'}}>
                                        <div className="sectionSubTitle">Receipt Summary</div>
                                        <div>
                                            <span style={{fontSize: '25px', color: '#2aa9da'}}>{CURRENCY} {formatNumber(stateData.transactionState.current.receipt)}</span>
                                            <span className="positiveTag">
                                                <ArrowUpOutlined /> 9.98%
                                            </span>
                                        </div>
                                        <div className="sectionDescription">
                                            {CURRENCY} {formatNumber(stateData.transactionState.last.receipt)} was in previous 30 days
                                        </div>
                                    </div>
                                    <div style={{paddingTop: '20px'}}>
                                        <div className="sectionSubTitle">Payment Summary</div>
                                        <div>
                                            <span style={{fontSize: '25px', color: '#5f2daf'}}>{CURRENCY} {formatNumber(stateData.transactionState.current.payment)}</span>
                                            <span className="negativeTag">
                                                <ArrowDownOutlined /> -2.45%
                                            </span>
                                        </div>
                                        <div className="sectionDescription">
                                            {CURRENCY} {formatNumber(stateData.transactionState.last.payment)} was in previous 30 days
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                                    <ReceiptAndPaymentChart />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Card>
                            <div className="sectionTitle">Balance Summary</div>
                            <div className="sectionDescription">Current Cash & Bank Balance</div>
                            <Row gutter={24} style={{height: '160px'}}>
                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <CurrentBalance />
                                </Col>
                                <Col xl={12}>
                                    <div style={{paddingTop: '20px'}}>
                                        <div className="sectionSubTitle">Balance in Cash</div>
                                        <div>
                                            <span style={{fontSize: '25px', color: '#2aa9da'}}>{CURRENCY} {formatNumber(stateData.transactionState.current.receipt)}</span>
                                            <span className="positiveTag">
                                                <ArrowUpOutlined /> 9.98%
                                            </span>
                                        </div>
                                        <div className="sectionDescription">
                                            {CURRENCY} {formatNumber(stateData.transactionState.last.receipt)} was in previous 30 days
                                        </div>
                                    </div>
                                    <div style={{paddingTop: '20px'}}>
                                        <div className="sectionSubTitle">Balance in Bank</div>
                                        <div>
                                            <span style={{fontSize: '25px', color: '#5f2daf'}}>{CURRENCY} {formatNumber(stateData.transactionState.current.payment)}</span>
                                            <span className="negativeTag">
                                                <ArrowDownOutlined /> -2.45%
                                            </span>
                                        </div>
                                        <div className="sectionDescription">
                                            {CURRENCY} {formatNumber(stateData.transactionState.last.payment)} was in previous 30 days
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Card>
                            <Row gutter={24}>
                                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                                    <div className="sectionTitle">Account Balance Summary</div>
                                    <div className="sectionDescription">Cash & Cash Equivalent Head</div>
                                    <div style={{paddingTop: '20px', height: '300px'}}>
                                        <AccountBalance data={AccountBalanceData} />
                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                                    <div style={{paddingTop: '80px'}}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Account</th>
                                                    <th style={{textAlign: 'right !important'}}>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                AccountBalanceData?.map((item)=>{
                                                    return <tr>
                                                        <td>{item.name}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(item.value)}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                            <tfoot>
                                                 <tr>
                                                    <th>Total</th>
                                                    <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(totalCurrentBalance)}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                                    <div className="sectionTitle">Income & Expenditure</div>
                                    <div className="sectionDescription">Last 30 Days</div>
                                    <div style={{paddingTop: '20px', height: '300px'}}>
                                        <IncomeAndExpendiatures data={incomeExpense} />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

            </Auxiliary>
        </div>
    );
};

export default FinancialDashboard;

import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";


export const IncomeAndExpendiatures = ({data}) => {

    return <ResponsiveContainer width="100%" height={280}>
        <AreaChart data={data}>
            <defs>
                <linearGradient
                    id="colorIncome"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                >
                    <stop
                        offset="5%"
                        stopColor="#27496d"
                        stopOpacity={0.8}
                    />
                    <stopgetDashboardData
                        offset="95%"
                        stopColor="#00a8cc"
                        stopOpacity={0.4}
                    />
                </linearGradient>
                <linearGradient
                    id="colorExpnese"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                >
                    <stop
                        offset="5%"
                        stopColor="#bf163f"
                        stopOpacity={0.8}
                    />
                    <stop
                        offset="95%"
                        stopColor="#bf163f"
                        stopOpacity={0.4}
                    />
                </linearGradient>
            </defs>
            <XAxis dataKey="name"/>
            <YAxis
                tickFormatter={(value) =>
                    new Intl.NumberFormat("en", {
                        notation: "compact",
                        compactDisplay: "short",
                    }).format(value)
                }
            />
            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip
                formatter={(value) =>
                    new Intl.NumberFormat("en").format(value)
                }
            />
            <Area
                type="monotone"
                dataKey="Income"
                label={"Income"}
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorIncome)"
            />
            <Area
                type="monotone"
                dataKey="Expense"
                label={"Expense"}
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorExpnese)"
            />
        </AreaChart>
    </ResponsiveContainer>

}
import React, {useState, useEffect} from "react";
import {Form, Col, Input, Collapse, Card, Checkbox, Table, notification} from 'antd';
import jsonfile from './collection.json'
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/ActionButton2';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import CircularProgress from "../../../../components/CircularProgress";

const Search = Input.Search;
const Panel = Collapse.Panel;


const CollectionHistoryV2 = () => {
    const value = 0;
    const defaultCheckedList = jsonfile.listView.title.map((item) => {
        if(item !== 'id'){
            return item
        }
    });
    const [loader, setLoader] = useState(false);
    const [total,setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        reqURLsum: Config.apiserver+"donation/collectionsummary",
        filtervalue : '',
        subtitle : '',
    });
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState(defaultCheckedList);

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.list,
                reqURLsum: Config.apiserver+"donation/collectionsummary"
            }
        );
    }, []);
    
    
    useEffect(() => {
        getData()
    }, [state.reqURL]);
    
    const getData = (url = "") => {
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }
        // document.getElementById("loader").style.display = "block";
        setLoader(true)
        axios.get(urls)
            .then((res) => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                } else {
                    setData([])
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoader(false)
                // document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                setLoader(false)
                // document.getElementById("loader").style.display = "none";
            })
    }

    const getTotalSummary = (url) => {
        axios.get(url)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    setTotal(res.data.total);
                }
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                //setLoading({loading:false});
                //setResponse([]);
                //document.getElementById("loader").style.display = "none";
            });
    }

    useEffect(() => {
        getTotalSummary(state.reqURLsum);
    }, []);

    const token = localStorage.getItem('token');

    const history = useHistory();

    const getPrintBtn = (id) => {
        const url = Config.apiserver+"entries/mreceipt/"+id
        return <a href={Config.baseurl+"moneyreceipt.html?token="+token+"&url="+window.btoa(url)} target="_blank" className="btn btn-primary btn-circle"><i className="fas fa-print"></i></a>
    }
    
    const columns = [
        {
            title: <IntlMessages id="ID"/>,
            dataIndex: "id",
            key: "id",
        },
        {
            title: <IntlMessages id="Date"/>,
            dataIndex: "date",
            key: "date",
            width: "100px",
            render: (value, row, index) => CisUI().DateFormat(value)
        },
        {
            title: <IntlMessages id="Contribution Type"/>,
            dataIndex: "contribution_type",
            key: "contribution_type",
        },
        {
            title: <IntlMessages id="Amount(₵)"/>,
            dataIndex: "amount",
            key: "amount",
            align: 'right',
            width: "70px",
            render: (value, row, index) => CisUI().getCurrencyFormattedV2(value)
        },
        {
            title: <IntlMessages id="Local"/>,
            dataIndex: "local",
            key: "local",
        },
        {
            title: <IntlMessages id="District"/>,
            dataIndex: "district",
            key: "district",
        },
        {
            title: <IntlMessages id="Region"/>,
            dataIndex: "region",
            key: "region",
        },
        {
            title: <IntlMessages id='Action'/>,
            dataIndex: 'action',
            key: 'action',
            // width: "70px",
            // fixed: "left",
            render: (value, row, index) => (
                <>
                {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            )
        }
    
    ];
    
    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
    }));
    
    // const newColumns = columns.map((item) => ({
    //     ...item,
    //     hidden: !checkedList.includes(item.key),
    // }));
    const newColumns = columns.filter((cols, index) => checkedList.includes(cols.dataIndex) )
    
    // console.log("checked", newColumns)

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values,"month");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        const newSumURL = Config.apiserver+"donation/collectionsummary/?"+qsup;
        setState({reqURL: newURL, reqURLsum : newSumURL});
        setTotal(0);
        getTotalSummary(newSumURL);
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1,"month", CisUI().PeriodFiled(form,"month"))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../"+jsonfile.urls.add,"",state.reqURL,"data","contributions",jsonfile,state.subtitle, "landscape")}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                {loader ? <CircularProgress />
                    :
                    <>
                        <Checkbox.Group
                            value={checkedList}
                            options={options}
                            onChange={(value) => {
                                setCheckedList(value);
                            }}
                            style={{ marginBottom: "15px" }}
                        />
                        
                        <Table
                            columns={newColumns}
                            dataSource={data}
                            pagination={{ defaultPageSize: 50, position: ['bottomCenter'] }}
                        />
                        
                        <div className="row">
                            <div className="col-md-12" style={{textAlign: 'center'}}>
                                <h4>Total : {CisUI().getCurrencyFormated1(total || 0)}</h4>
                            </div>
                        </div>
                    </>
                }
            </Card>
        </>
    );
};

export default CollectionHistoryV2;
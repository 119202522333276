import React from 'react'

const CreateCartItem = ({name, price}) => {

    // useEffect(() => {
    //     getproduct();
    //     // console.log(cartIt);
    // }, []);
    // const onSearch = (() => {
    //     console.log("hello");
    // })

   

    // const itemAddToCart = (name, price) => {
    //     console.log("hello");
    //     // console.log(name, price);

    //     cartItems.push({ name, price })
    //     // console.log(cartItems.length);

    //     cartItems.map((item, index) => {
    //         console.log(item.name)
    //     })
    // }

    // useEffect(() => {
    //     itemAddToCart()
    // }, [cartItems]);

    return (
        <div>
            <h1>{name}</h1>
            {console.log("Hello from Child")}
            {console.log(name)}
            <p>{price}</p>
        </div>
    )
}

export default CreateCartItem

import React, {useEffect, useState} from "react";
import {Layout, Modal, Popover} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import {switchLanguage, toggleCollapsedSideNav, userSignOut} from "../../appRedux/actions";
import UserInfo from "../../components/UserInfo";
import Auxiliary from "util/Auxiliary";
import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";
import {CisUI} from "../../util/CISUI";
import UserProfile from "../Sidebar/UserProfile";
import Shortcuts from "../../components/Shortcuts";
import {HelpOutline} from "@material-ui/icons";
import slocalStorage from "react-secure-storage";

const {Header} = Layout;

const Topbar = () => {

    const {locale, navStyle} = useSelector(({settings}) => settings);
    const navCollapsed = useSelector(({common}) => common.navCollapsed);
    const width = useSelector(({common}) => common.width);
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();
    const Logo = "/assets/images/penterpv1.png";

    const [token, setToken] = useState("");

    useEffect(() => {
        const stoken = slocalStorage.getItem("token");
        setToken(btoa(stoken));
    }, []);

    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
            <ul className="gx-sub-popover">
                {languageData.map(language =>
                    <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() =>
                        dispatch(switchLanguage(language))
                    }>
                        <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
                        <span className="gx-language-text">{language.name}</span>
                    </li>
                )}
            </ul>
        </CustomScrollbars>);

    const updateSearchChatUser = (evt) => {
        setSearchText(evt.target.value);
    };

    // const handleChange = (value) => {
    // }
    
    const showLogoutConfirm = () => {
        Modal.confirm({
            title: 'Are you sure you want logout?',
            centered: true,
            onOk: () => dispatch(userSignOut()),
            okText: 'Yes',
            cancelText: 'No',
            // content: 'Bla bla ...',
            footer: (_, { OkBtn, CancelBtn }) => (
                <div style={{ textAlign: "center" }}>
                    {/*<CancelBtn />*/}
                    <OkBtn />
                </div>
            ),
        });
    };

    return (
        <div>
            <Header style={{background: '#2F4E67'}}>
                {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
                    <div className="gx-linebar gx-mr-3">
                        <i className="gx-icon-btn icon icon-menu"
                           onClick={() => {
                               dispatch(toggleCollapsedSideNav(!navCollapsed));
                           }}
                        />
                    </div> : null}
                {width >= TAB_SIZE &&
                    <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
                        <img alt="" src={Logo} style={{width: '50px'}}/>
                    </Link>
                }

                <div style={{fontWeight: 'bold', fontSize: "20px"}}>{CisUI().getUserInfo('companyName')}</div>

                <ul className="gx-header-notifications gx-ml-auto">
                    {/*<li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none" style={{display: 'none !important'}}>*/}
                    {/*    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={*/}
                    {/*        <SearchBox styleName="gx-popover-search-bar"*/}
                    {/*                   placeholder="Search in app..."*/}
                    {/*                   onChange={updateSearchChatUser}*/}
                    {/*                   value={searchText}/>*/}
                    {/*    } trigger="click">*/}
                    {/*        <span className="gx-pointer gx-d-block" style={{color: 'white'}}><i*/}
                    {/*            className="icon icon-search-new"/></span>*/}
                    {/*    </Popover>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <a*/}
                    {/*        href={"http://localhost:3001/emslogin?token=" + token}*/}
                    {/*        title={"Support Portal"}*/}
                    {/*        target="_blank"*/}
                    {/*        rel="noopener noreferrer"*/}
                    {/*    >*/}
                    {/*        <HelpOutline style={{ fontSize: "30px" }} />*/}
                    {/*    </a>*/}
                    {/*</li>*/}

                    {width >= TAB_SIZE && <li style={{display: "flex", gap: "10px", color: 'white'}}>
                        <a href="https://support.penterp.com/" target="_blank">
                            <i className="icon icon-sweet-alert" style={{
                                position: "relative",
                                top: "3px",
                                margin: "0px 5px 0px 0px",
                                fontSize: "15px",
                                color: 'white'
                            }}/>
                            <span className="support-title" style={{fontSize: "15px", color: "white"}}>Help</span>
                        </a>
                    </li>}

                    {/*<li className="gx-language">*/}
                    {/*    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"*/}
                    {/*             content={languageMenu()}*/}
                    {/*             trigger="click">*/}
                    {/*                <span className="gx-pointer gx-flex-row gx-align-items-center">*/}
                    {/*                  <i className={`flag flag-24 flag-${locale.icon}`}/>*/}
                    {/*                  <span className="gx-pl-2 gx-language-name" style={{color: 'white'}}>{locale.name}</span>*/}
                    {/*                  <i className="icon icon-chevron-down gx-pl-2" style={{color: 'white'}}/>*/}
                    {/*                </span>*/}
                    {/*    </Popover>*/}
                    {/*</li>*/}

                    <Auxiliary>
                        <li className="gx-user-nav">
                            <UserProfile />
                        </li>
                    </Auxiliary>

                </ul>
            </Header>
            <div style={{
                height: '35px',
                color: 'white',
                background: '#487091',
                padding: '0 32px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }} className="no-print" >
                <div style={{display: 'flex', columnGap: '18px', fontSize: '16px', marginTop: '6px'}}>
                    {/*<div>*/}
                    {/*    <i class='icon icon-avatar' style={{marginRight: '3px'}}></i>*/}
                    {/*    <i className="icon icon-chevron-down"/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <i class='icon icon-setting' style={{marginRight: '3px'}}></i>*/}
                    {/*    <i className="icon icon-chevron-down"/>*/}
                    {/*</div>*/}
                    <div>
                        {/*<span className="currency" style={{marginRight: '20px'}}>*/}
                        {/*    <Popover overlayClassName="gx-popover-horizantal1" placement="bottomRight" content={<Shortcuts />} trigger="click">*/}
                        {/*        <span className="gx-pointer gx-status-pos gx-d-block">*/}
                        {/*          <i className="icon icon-apps icon__color"/> Favorites*/}
                        {/*        </span>*/}
                        {/*    </Popover>*/}
                        {/*</span>*/}
                        {/*<span className="currency" style={{marginRight: '20px'}}>₵</span>*/}
                        {/*<span className="gx-language" onClick={() => dispatch(userSignOut())}>*/}
                        {/*    <button type="button" className="ant-btn ant-btn-danger"*/}
                        {/*            style={{height: '40px', marginTop: '10px'}}><span>Logout</span></button>*/}
                        {/*</span>*/}

                        <ul className="gx-header-notifications gx-ml-auto" >
                            <li className="gx-msg">
                                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<Shortcuts />} trigger="click">
                                    <span className="gx-pointer gx-status-pos gx-d-block">
                                      <i className="icon icon-apps icon__color"/> Favorites
                                    </span>
                                </Popover>
                            </li>

                            <li>
                                <li>
                                    <Link to={"/changelayout"}><i className="icon icon-callout" style={{color: 'white'}}></i></Link>
                                </li>
                            </li>
                            <li className="currency">₵</li>
                            <li className="gx-language" onClick={showLogoutConfirm}>
                                <button type="button" className="ant-btn ant-btn-danger"
                                        style={{height: '40px', marginTop: '10px'}}><span>Logout</span></button>
                            </li>

                            {width >= TAB_SIZE ? null :
                                <Auxiliary>
                                    <li className="gx-user-nav"><UserInfo/></li>
                                </Auxiliary>
                            }
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Topbar;
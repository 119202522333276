import React, {useState, useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import {useSelector} from "react-redux";
import {CisUI} from '../../util/CISUI';
import axios from "../../util/Api";
import Config from "./../../util/config";


const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed, match}) => {
    const [userMenu, setUserMenu] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(['/main/dashboard/crm']);
    const [defaultOpenKeys, setDefaultOpenKeys] = useState(['/main/dashboard/crm']);
    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);
    
    useEffect(() => {
        getUserMenu();

    }, []);

    const getUserMenu = async () => {
        // const userData = localStorage.getItem("userData");
        // const dataparse = JSON.parse(userData);
        // const userId = dataparse.user_name || "";
        //const URL = Config.apiserver+"getusermenu/?username=superadmin";

        const URL = Config.apiserver+"getusermenuv2/?username="+CisUI().getUserInfo("user_name");
        return await axios.get(URL)
            .then((response) => {
         
                if(response.status === 200) {
                    let data = response?.data?.allmenu;
                    const usermenu = data.length > 0 && data.map((item, index) => {
                        return {
                            label: item.label,
                            key: item.urlkey,
                            icon: <i className={item.icon}/>,
                            children: item?.submodule?.map((child) => {
                                if (`/${item.urlkey}/${child.urlkey}` === pathname) {
                                    setSelectedKeys([`/${item.urlkey}/${child.urlkey}`]);
                                    setDefaultOpenKeys([item.urlkey]);
                                }
                                
                                return {
                                    label:
                                        child?.menu.length === 0 ? (
                                            <Link to={`/${item.urlkey}/${child.urlkey}`}>
                                                {/*<IntlMessages id={child.label} />*/}
                                                {child.name}
                                            </Link>
                                        ) : (
                                            <p> {child.name} </p>
                                        ),
                                    key: `/${item.urlkey}/${child.urlkey}`,
                                    icon: <i className="icon icon-menu-right" />,
                                    children: child?.menu?.length !== 0 ? child?.menu?.map((child2) => {
                                                if (`/${item.urlkey}/${child2.urlkey}` === pathname) {
                                                    // console.log("keys...", `/${item.urlkey}/${child2.urlkey}`)
                                                    setSelectedKeys([`/${item.urlkey}/${child2.urlkey}`]);
                                                    setDefaultOpenKeys([item.urlkey, `/${item.urlkey}/${child.urlkey}`]);
                                                }
                                                return {
                                                    label: (
                                                        <Link to={`/${item.urlkey}/${child2.urlkey}`}>
                                                            {child2.name}
                                                        </Link>
                                                    ),
                                                    icon: <i className="icon icon-circle iconsm"/>,
                                                    key: `/${item.urlkey}/${child2.urlkey}`,
                                                };
                                            })
                                            : null,
                                };
                            }),
                        }
                    })
                    usermenu.unshift({
                        label: <Link to="/main/dashboard/crm">Dashboard</Link>,
                        key: `/main/dashboard/crm`,
                        icon: <i className="icon icon-dasbhoard"/>,
                        className: "dashboardMenu"
                    });
                    console.log("usermenu", usermenu)
                    setUserMenu(usermenu)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    
    // console.log("keys....ope", defaultOpenKeys)
    // console.log("keys....sle", selectedKeys)
    return (
        <>
            <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
            <div style={{
                height: '35px',
                color: 'white',
                background: '#487091',
                padding: '0 32px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }}> </div>
            <div className="gx-sidebar-content">
                <CustomScrollbars className="gx-layout-sider-scrollbar">
                    <Menu
                        // defaultOpenKeys={['user', '/user/userinfo']}
                        // defaultSelectedKeys={['/user/userlist']}
                  
                        selectedKeys={selectedKeys}
                        openKeys={defaultOpenKeys}
                        theme={themeType === THEME_TYPE_LITE ? "lite" : "lite"}
                        mode="inline"
                        className="poc_sidebar"
                        items={userMenu}
                        onClick={(e) => setSelectedKeys([e.key?.toString()])}
                        onOpenChange={(openKeys) => setDefaultOpenKeys(openKeys)}
                    />
                </CustomScrollbars>
            </div>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;


import React, {useState, useEffect} from "react";
import {Form, Card,notification, Row, Col, Button, Input, Select} from 'antd';
import jsonfile from './bank_account.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputFieldV2 from "../../../../util/InputFieldV2";
import SelectData from "../../../../util/selectdata";
import IntlMessages from "../../../../util/IntlMessages";

const {Option} = Select;

const NewBankAccount = (props) => {

    const [loading, setLoading] = useState([]);
    const [accounts, setCashAccount] = useState([]);
    const [userData, setUserData] = useState({});
    const [form] = Form.useForm();
    const history = useHistory();
    const formRef = React.createRef();

    const [check, setCheck] = useState({
        input_size_width : 810,
        input_size_height : 384,
        input_date_left : 605,
        input_date_top : 77,
        input_pay_to_top : 38,
        input_pay_to_left : 96,
        input_amount_top : 34,
        input_amount_left : 576,
        input_amount_word_left : 125,
        input_amount_word_top : -34,
        input_signature_left : 624,
        input_signature_top : 48,
        input_seal_left : 384,
        input_seal_top : -48,
    });

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    // let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    const getAccountDetails = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(jsonfile.urls.view+"/"+userID)
            .then(res => {
                // console.log(res)
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    setUserData(res.data?.data);
                }

            })
            .catch(errors => {
                console.log(errors);
                document.getElementById("loader").style.display = "none";
            });
    };

    if(isEdit === 1) {
        // userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    useEffect(()=>{
        if(isEdit === 1) {
            getAccountDetails();
        }
    },[]);

    const Companies = SelectData("company/companylists");

    const onFinish = (values) => {
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }

            })
            .catch(errors => {
                console.log(errors);
                document.getElementById("loader").style.display = "none";
            });
    };

    const onChangeValue = e => {
        console.log(e);
    }

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        const comID = CisUI().getUserInfo('com_id');
        if(comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            const company = value;
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const updateCompanyList = () => {
        axios.get("billentrydata?com_id="+userData.company_id)
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    setCashAccount(res.data.cashaccount);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    //console.log("UserData : "+userData);

    return (
        <Card title={<IntlMessages id={Title} />} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >

                {InputFieldV2(jsonfile.input,userData,isEdit,props)}
                {
                    isEdit === 0 ? <>
                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </Col>
                        </Row>
                    </>:<>
                    {
                        userData?.id  ? <>
                            <Row gutter={24}>
                                <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                    <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                                </Col>
                            </Row>
                        </>:<></>
                    }
                    </>
                }

            </Form>
        </Card>
   );
};

export default NewBankAccount;
import {Button, Card, Col, DatePicker, Form, Input, message, notification, Popover, Row, Select, Upload} from 'antd';
import React, {useEffect, useState} from "react";
import jsonfile from './localexpenses.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config, {fileserver} from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import moment from "moment";
import {UploadOutlined} from "@ant-design/icons";

const { Option } = Select;
// const Option = Select.Option;
const {TextArea} = Input;


const NewExpenses = (props) => {

    const formRef = React.createRef();
    const [dataOptions, setDataOptions] = useState([]);
    const [loading, setLoading] = useState([]);
    const [inputList, setInputList] = useState([]);
    const [msg, setMsg] = useState("");
    const [lineitems, setLineItems] = useState([]);


    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();
    

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        console.log("ExpenseData : "+inputList);
        document.getElementById("loader").style.display = "block";
        const newInputList = inputList.map((item, index) => ({
            amount: item.amount,
            expensetype: item.expensetype,
            files: item.files,
            note: item.note
        }));
        // console.log("AfterFilter : "+newInputList);
        values['expenses'] = newInputList.filter(value => JSON.stringify(value) !== '{}');
        console.log("values", values)
        // return
        let msg = "Sorry! request not processed, please try again";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Success',
                        type : "warning",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
                document.getElementById("loader").style.display = "none";
            });
    };

    // const dataOptions = SelectData("donation/expensetype?area=5");

    useEffect(() => {
        let options = [];
        axios.get(Config.apiserver+'donation/expensetype?area=5')
            .then((res) => {
                if(res.data.status === 1){
                    res.data.data.map((item, index) => {
                        options.push({
                            expensetype : item.id,
                            name: item.name,
                            id: item.id,
                            amount : '',
                            note : '',
                            files : '',
                            attachments: []
                        })
                    })
                    setDataOptions(options)
                }
                else{
                    setDataOptions([])
                }
            })
            .catch(error => console.log(error))
        
        setInputList(options)
    }, []);


    const handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;

            const list = [...inputList, { }];

            list[index]["expensetype"] = dataOptions[index].id;
            if(name === 'amount') {
                list[index][name] = value || 0;
            }
            else {
                list[index][name] = value;
            }
            setInputList(list);
            //list[index]["expensetype"] = dataOptions[index].id;
            setInputList(list);
            // console.log(JSON.stringify(inputList));
        }
        else {
            const list = [...inputList];
            list[index]["expensetype"] = e;
            setInputList(list);
        }
    };

// handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

// handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { }]);
    };

    const onDistrictChange = e => {
        const month = form.getFieldValue('month');
        const localID = form.getFieldValue('district_id');
        const mnt = moment(month).format('YYYYMM');
        
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+"donation/lcexpneseopenbymonth/"+mnt+"?req_id="+localID+"&area=District")
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if(res.data.data.length > 0) {
                    setMsg("Note: Expenses have been entered for the selected month. Submitting a new entry will overwrite the existing one.");
                    setLineItems(res.data.data);
                    const data = res.data.data;
                    console.log("data", data)
                    const newData = [...dataOptions];
                    newData.forEach((inx,i)=>{
                        const fl = data.filter(item => item.account_id === inx.id);
                        console.log("filess", fl)
                        if(fl.length > 0) {
                            newData[i].amount = fl[0].amount;
                            newData[i].note = fl[0].notes;
                            newData[i].attachments = fl[0].allattachment
                        }
                        else{
                            dataOptions[i].amount = '';
                            dataOptions[i].note = '';
                            dataOptions[i].attachments = [];
                        }
                    });
                    console.log("newData", newData)
                    setDataOptions(newData)
                }
                else {
                    setMsg("");
                    setLineItems([]);
                    const newData = [...dataOptions];
                    newData.forEach((inx,i)=>{
                        newData[i].amount = '';
                        newData[i].note = '';
                        dataOptions[i].attachments = [];
                    });
                    setDataOptions(newData)
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                setMsg("");
                setLineItems([]);
                dataOptions.map((inx, i) => {
                    dataOptions[i].amount = '';
                    dataOptions[i].note = '';
                });
                console.log(errors);
                document.getElementById("loader").style.display = "none";
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
            });
    }

    const onMonthChange = e => {
        const month = form.getFieldValue('month');
        let localID = 0;
        if(CisUI().getUserInfo('comarea') === 5) {
            localID = CisUI().getUserInfo('district_id');
        }
        else {
            localID = form.getFieldValue('district_id');
        }
        const mnt = moment(month).format('YYYYMM');

        if(localID > 0 && mnt > 0) {

            document.getElementById("loader").style.display = "block";
            axios.get(Config.apiserver + "donation/lcexpneseopenbymonth/" + mnt + "?req_id=" + localID + "&area=District")
                .then(res => {
                    console.log(res)
                    document.getElementById("loader").style.display = "none";
                    if (res.data.data.length > 0) {
                        setMsg("Note: Expenses have been entered for the selected month. Submitting a new entry will overwrite the existing one.");
                        setLineItems(res.data.data);
                        const data = res.data.data;
                        const newData = [...dataOptions];
                        newData.forEach((inx,i)=>{
                            const fl = data.filter(item => item.account_id === inx.id);
                            if(fl.length > 0) {
                                newData[i].amount = fl[0].amount;
                                newData[i].note = fl[0].notes;
                                newData[i].attachments = fl[0].allattachment
                            }
                            else{
                                dataOptions[i].amount = '';
                                dataOptions[i].note = '';
                                dataOptions[i].attachments = [];
                            }
                        });
                        setDataOptions(newData)
                    } else {
                        setMsg("");
                        setLineItems([]);
                        const newData = [...dataOptions];
                        newData.forEach((inx,i)=>{
                            newData[i].amount = '';
                            newData[i].note = '';
                            dataOptions[i].attachments = [];
                        });
                        setDataOptions(newData)
                    }
                    setLoading({loading: false});
                })
                .catch(errors => {
                    setMsg("");
                    setLineItems([]);
                    dataOptions.map((inx, i) => {
                        dataOptions[i].amount = '';
                        dataOptions[i].note = '';
                    });
                    console.log(errors);
                    document.getElementById("loader").style.display = "none";
                    // msg = (errors);
                    setLoading({loading: false});
                    //setResponse([]);
                });
        }
    }

    const districtList = RemoteJson("district");
    
    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...inputList];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setInputList(list);
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }
                    
                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }

    return (
        <Card title={Title}>
            {CisUI().showLoading}
            {
                isEdit === 1
                    ? CisUI().addAction("../../"+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {/*{InputOnly(jsonfile.input,userData,isEdit)}*/}

                <Row gutter={24}>
                    <div className="col-md-4 col-sm-6">
                        <Form.Item
                            name="date"
                            label="Date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a date"
                                },
                            ]}
                        >
                            <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat}
                                        className="gx-mb-3 gx-w-100"
                                        placeholder="Date"/>
                        </Form.Item>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <Form.Item
                            name="month"
                            label="Month"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a date"
                                },
                            ]}
                        >
                            <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} onChange={onMonthChange} picker="month" format={"YYYY-MM"} className="gx-mb-3 gx-w-100"
                                        placeholder={"Month"}/>
                        </Form.Item>
                    </div>
                    {
                        CisUI().getUserInfo('comarea') <= 4 ?
                            <div className="col-md-4 col-sm-6">
                                <Form.Item
                                    name="district_id"
                                    label="District"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a district"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a district"
                                        optionFilterProp="children"
                                        onChange={onDistrictChange}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            districtList.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div> : <></>
                    }
                </Row>

                <Row>
                    <div className={"col-md-12"}>
                        <h3 style={{color: "red"}}>{msg}</h3>
                    </div>
                </Row>

                <Row gutter={24}>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Account Name</th>
                            <th>Amount</th>
                            <th>Note</th>
                            <th>Attachment</th>
                        </tr>
                        </thead>

                        {dataOptions.map((x, i) => {
                            return (
                                <>
                                    <tr>
                                        <td>{x.name}
                                            <input type="hidden" id={"expensetype_"+x.id} name="expensetype" value={x.id} />
                                        </td>
                                        <td>
                                            <input
                                                className="ant-input"
                                                name="amount"
                                                id={"amount_"+x.id}
                                                placeholder="Amount"
                                                value={x.amount}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className="ant-input"
                                                name="note"
                                                id={"note_"+x.id}
                                                placeholder="Enter a note"
                                                value={x.note}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </td>
                                        {/*<td>*/}
                                        {/*    <input*/}
                                        {/*        className="ant-input"*/}
                                        {/*        name="file"*/}
                                        {/*        id={"file_"+x.id}*/}
                                        {/*        type="file"*/}
                                        {/*        value={x.file}*/}
                                        {/*        onChange={e => handleInputChange(e, i)}*/}
                                        {/*    />*/}
                                        {/*</td>*/}
                                        <td>
                                            <div style={{display: "flex", columnGap: "10px", marginTop: "10px"}}>
                                                <Upload data={
                                                    {
                                                        file: formData,
                                                        type: "districtdonation",
                                                        file_id: fileUID,
                                                        row_id: x.id,
                                                        line_index: i
                                                    }
                                                } {...uplod}>
                                                    <Button icon={<UploadOutlined/>}></Button>
                                                </Upload>
                                                {
                                                    x?.attachments?.length > 0 &&
                                                    <Popover placement="topLeft" content={<>
                                                        {
                                                            x?.attachments?.map((item, index) =>
                                                                <a href={fileserver + item.location} target="_blank"
                                                                   style={{display: "block", padding: "5px"}}>
                                                                    <i className="fas fa-folder-open"/> {item.file_name}
                                                                </a>
                                                            )
                                                        }
                                                    </>} title="All Attachments">
                                                        <button className="btn btn-primary btn-circle" type="button">
                                                            <i className="fas fa-link"/>
                                                        </button>
                                                    </Popover>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            );
                        })}

                    </table>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>


        </Card>
    );
};

export default NewExpenses;
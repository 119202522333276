import React, {useState, useEffect, useRef} from "react";
import {Form, Table, Collapse, Card, notification, Spin} from 'antd';
import jsonfile from './ledgerbybeneficiary.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import AdvancedSearchBeneficiary from "../../../../util/AdvanceSearchWithBeneficiary";
import localStorage from "react-secure-storage";
import {CaretRightOutlined} from "@ant-design/icons";
import { useReactToPrint } from 'react-to-print';

// const reactToPrintFn = useReactToPrint({ contentRef });

const {Panel} = Collapse;


const GeneralLedgerByBeneficiaryV1 = (props) => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const accountID = params.get("account_id") || 0;
    const date = params.get("date") || "";
    const history = useHistory();
    const [form] = Form.useForm();
    const [activeKeys, setActiveKeys] = useState([]);
    let url = Config.apiserver+jsonfile.urls.list;
    const contentRef = useRef();
    const reactToPrintFn = useReactToPrint({ contentRef });
    if(parseInt(accountID) > 0) {
        url = Config.apiserver+jsonfile.urls.list+"?account_id="+accountID+"&date="+date;
    }

    const [state,setState] = useState({
        reqURL: url,
        filtervalue : '',
        subtitle : '',
    });

    const formRef = React.createRef();

    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);

    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         // Check if Ctrl (or Cmd on Mac) + P is pressed
    //         if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
    //             event.preventDefault();
    //             reactToPrintFn();
    //         }
    //     };
    //
    //     document.addEventListener('keydown', handleKeyDown);
    //
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, [reactToPrintFn]);

    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }
        setIsLoading(true);

        //setFirstLoad(false);
        setActiveKeys([]);
        axios.get(urls)
            .then((res) => {
                // console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                    // let keys = [];
                    // res?.data?.data?.map((item, index) =>
                    //     keys.push(item?.id?.toString())
                    // )
                    setActiveKeys(res?.data?.keys);
                } else {
                    setData([])
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setIsLoading(false)
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                document.getElementById("loader").style.display = "none";
            })
            .finally(() => {
                setIsLoading(false);
            });

    }


    const getDetailsLink = (source,id) => {

        if(source === 'bill') {
            return "../../"+jsonfile.detaillink.bill+"/"+id;
        }
        else if(source === 'journal') {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
        else if(source === 'ledger') {
            return "../../"+jsonfile.detaillink.ledgerdetails+"/"+id;
        }
        else if(source === 'invoice') {
            return "../../"+jsonfile.detaillink.invoice+"/"+id;
        }
        else if(source === 'creditnote') {
            return "../../"+jsonfile.detaillink.creditnote+"/"+id;
        }
        else if(source === 'voucher') {
            return "../../"+jsonfile.detaillink.voucherdetails+"/"+id;
        }
        else if(source === 'transaction') {
            // return "../../"+jsonfile.detaillink.transactiondetails+"/"+id;
            return "";
        }
        else {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
    }

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.list + "?" + qsup;
        setData([]);
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {/*{AdvancedSearch(jsonfile.search,"",1,"",CisUI().PeriodFiled(form))}*/}
                        {AdvancedSearchBeneficiary(jsonfile.search,"",1,"date",CisUI().PeriodFiled(form),form)}

                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const ListActionButtonReportXLS = (url="",querystring="",apiURL="",dataParameter="",filename="",fullJson={}, subtitle="",printmode="portrait") =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/coploading.gif" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                {
                    jsonfile.urls.hasOwnProperty("list") ?
                        <>
                            {/*<button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIDataXLS(apiURL,querystring)} title="Export to Excel"><i*/}
                            {/*    className="fas fa-file-excel"/></button>*/}
                            <button onClick={(e) => print(apiURL,dataParameter,filename)} className="btn btn-export-print btn-circle-md" title="Print"><i
                                className="fas fa-print"/></button>
                        </> : "" }
            </div>
        );

    const print = (apiurl,datakey,filename) => {
        window.open(Config.baseurl+"reports/ledgerbybeneficiary.html?url="+window.btoa(apiurl)+"&filename="+filename+"&data="+datakey+"&title=General Ledger","_blank");
    }

    const getAPIDataXLS = (APIUrl, qrystring) => {
        let URL  = APIUrl+"/xls?"+qrystring+"&token="+localStorage.getItem("token") || 0;
        if(APIUrl.includes("?")) {
            const sp = APIUrl.split("?");
            URL  = sp[0]+"/xls?"+sp[1]+"&token="+localStorage.getItem("token") || 0;
        }

        console.log("URL :"+URL);
        window.open(URL,"_blank");
    };

    const getExtra = (
        <>
            {ListActionButtonReportXLS("../"+jsonfile.urls.add,"",state.reqURL,"data","general_ledger",jsonfile,"Journal", "landscape")}
        </>
    );

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: "20%",
            render: (date,record) =>
                record.type === 'entry'
                    // ? <><span>{record.account}</span><br/>{CisUI().DateFormat(date)}</>
                    ? <>{CisUI().DateFormat(date)}</>
                    : record.type === 'bb' ? <a  href={"?account_id="+record.id} style={{fontWeight: 'bold'}}>{record.account}</a> : ""
        },
        {
            title: 'Description/Memo',
            dataIndex: 'description',
            key: 'description',
            width: "35%",
            render:(value, record) =>

                // {console.log("desc111", record)}

                record.type === 'entry' ?
                    <>
                        {value && <><span>{value}</span><br/></>}
                        {record.ref_no && <><span>Ref. No: {record.ref_no}</span><br/></>}
                        {record.trx_ref && <span>Check#: {record.trx_ref}</span>}
                    </>
                    : <p style={{fontWeight: '500', color: "#072F4C", fontSize: "14px", padding: "10px 0"}}>{value}</p>
        },
        {
            title: 'Type',
            dataIndex: 'source',
            key: 'source',
            width: "15%",
            render: (source,record) => (
                <>
                    {
                        record.link_id > 0 ?
                            <span style={{fontWeight: 'bold'}}>{source}</span> :
                            <Link to={getDetailsLink(record.link_to,record.link_id)}>{source}</Link>
                    }
                </>
            ),
        },
        // {
        //     title: 'Beneficiary',
        //     dataIndex: 'beneficiary',
        //     key: 'beneficiary',
        // },

        // {
        //     title: 'Ref. No',
        //     dataIndex: 'ref_no',
        //     key: 'ref_no',
        // },
        // {
        //     title: 'Check#',
        //     dataIndex: 'trx_ref',
        //     key: 'trx_ref',
        // },
        {
            title: 'Debit',
            dataIndex: 'debit',
            key: 'debit',
            align: 'right',
            width: '10%',
            render: (debit,record) => (
                <>
                    {/*{*/}
                    {/*    record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(debit)}</span> : <Link to={getDetailsLink(record.link_to,record.link_id)}>{(record.type === "entry" || record.type === "end") ? CisUI().getCurrencyFormated1(debit) : ""}</Link>*/}
                    {/*}*/}
                    {

                    }
                    {
                        record.debit > 0 && (record.type === 'entry' || record.type === 'bb') &&
                        <span>{CisUI().getCurrencyFormated1(debit)}</span>
                    }
                    {
                        record.type === 'end' && <span>{CisUI().getCurrencyFormated1(debit)}</span>
                    }
                </>
            ),
        },
        {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'credit',
            align: 'right',
            width: '10%',
            render: (credit,record) => (
                <>
                    {/*{*/}
                    {/*    record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(credit)}</span> : <Link to={getDetailsLink(record.link_to,record.link_id)}>{(record.type === "entry" || record.type === "end") ? CisUI().getCurrencyFormated1(credit) : ""}</Link>*/}
                    {/*}*/}
                    {
                        record.credit > 0 &&
                        <span>{CisUI().getCurrencyFormated1(credit)}</span>
                    }
                </>
            ),
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_balance',
            key: 'current_balance',
            align: 'right',
            width: '15%',
            // className: (vl,record) => (
            //     <>{(record.type === 'bb' || record.type === 'end') ? boldtext : normaltext}</>
            // ),
            render: (source,record) => (
                <>
                    {/*{*/}
                    {/*    record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(record.current_balance)}</span> : <Link to={getDetailsLink(record.link_to,record.link_id)}>{CisUI().getCurrencyFormated1(record.current_balance)}</Link>*/}
                    {/*}*/}
                    {
                        record.type === 'bb' || record.type === 'end' ?
                            <span style={{ fontWeight: "500", color: "#072F4C", fontSize: "14px" }}>{CisUI().getCurrencyFormated1(record.current_balance)}</span>
                            :
                            <span>{CisUI().getCurrencyFormated1(record.current_balance)}</span>
                    }
                </>
            ),
        }
    ];

    const panelData = () => {
        return data?.map((item, index) => {
            console.log(`cis = account '${item.id}'`);

            return (
                <Panel
                    header={item.account}
                    key={item?.id}
                    // key="1"
                    style={{padding: "10px 0"}}
                >
                    {
                        item?.children?.map((child, idx) =>
                            <span style={{ display: 'block', marginBottom: '20px' }}>
                                <p style={{ color: "#4f7090", fontSize: '18px', marginBottom: '7px' }}>{child?.beneficiary}</p>
                                <Table
                                    columns={columns}
                                    className={'ledgerTable'}
                                    dataSource={child?.children}
                                    rowKey={(record) => record.reference}
                                    pagination={false}
                                />
                            </span>
                        )
                    }
                </Panel>
            );
        });
    }

    console.log("ac", activeKeys)

    return (
        <>
            {searchView}
            {CisUI().showLoading}

            <Card className="customLedger" extra={
                // <div className="row no-print">
                //     <button type="button"
                //             onClick={onPrintClick}
                //         className="btn btn-export-excel btn-circle-md" title="Print">
                //         <i className="fas fa-print"/>
                //     </button>
                // </div>
                getExtra
            }>
                {
                    data?.length > 0 ?
                        activeKeys.length > 0 ?
                            <Collapse
                                // bordered={true}
                                    // defaultActiveKey={['20505', '20515', '20524', '20564', '20606', '20609', '26069', '20653', '20655', '20662', '20670', '20674', '20675', '20677']}
                                    // defaultActiveKey={["1125-LITERATURE & PUBLICATIONS"]}
                                    // activeKey={activeKeys}
                                    defaultActiveKey={activeKeys}
                                    expandIconPosition="left"
                                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                                    className="site-collapse-custom-collapse"
                                >
                                    {panelData()}
                                </Collapse>
                                :
                                null
                            :
                            <p style={{fontSize: "18px", textAlign: "center"}}>No Data Found.</p>
                    }
                </Card>


                </>
                );
            };

            export default GeneralLedgerByBeneficiaryV1;
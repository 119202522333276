import React, {PureComponent, useState} from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import {Divider} from "antd";
import {formatNumber} from "../../../../util/CISUI";

export const CurrentBalance = ({}) => {
    const data = [
        { name: 'Cash', value: 40034567, fill: '#8884d8' },
        { name: 'Bank', value: 300897659, fill: '#82ca9d' },
        // { name: 'Group C', value: 300, fill: '#ffc658' },
        // { name: 'Group D', value: 200, fill: '#ff8042' },
    ];

    const [state, setState] = useState({activeIndex: 0})

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                {/* Multiple lines inside the circle */}
                <text x={cx} y={cy} dy={-10} textAnchor="middle" style={{fontSize: '11px', borderBottom: '1px #ffc658 solid' }} fill={fill}>
                    {payload.name}
                </text>
                <Divider />
                <text x={cx} y={cy} dy={10} textAnchor="middle" style={{fontSize: '11px'}} fill="#333">
                    {`${formatNumber(value)}`}
                </text>
                {/*<text x={cx} y={cy} dy={30} textAnchor="middle" style={{fontSize: '11px'}} fill="#999">*/}
                {/*    {`Rate: ${(percent * 100).toFixed(2)}%`}*/}
                {/*</text>*/}

                {/* Active sector */}
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />

                {/* Line from sector to value */}
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                {/*<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>*/}
                <text x={ex + (cos >= 0 ? 1 : -1) * 3} y={ey} style={{fontSize: '11px'}} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };



    const onPieEnter = (_, index) => {
        setState({
            activeIndex: index,
        });
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={200} height={200}>
                <Pie
                    activeIndex={state.activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={35}
                    outerRadius={40}
                    fill="#8884d8" // Default fill, but each segment uses its own color
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                />
            </PieChart>
        </ResponsiveContainer>
    );

}

import axios from 'axios';
import config from './config';
import  localStorage  from  "react-secure-storage";
let token = "";
if(localStorage.getItem("token") !== undefined) {
  token = localStorage.getItem('token') || "";
}

const axiosinstance = axios.create({
  baseURL: config.apiserver,
  headers: {
    "Content-Type": 'application/json',
    //   "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString(),

      "Authorization": token,
  }
});

export default axiosinstance;

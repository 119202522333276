import React, {Fragment, useEffect, useState} from "react";
import {
    Col,
    Input,
    Row,
    Select,
    Form,
    Button,
    Card,
    Divider,
    Typography,
    Upload,
    InputNumber,
    notification
} from "antd";
import config from "../../../../util/config";
import axios from "axios";
import jsonfile from "./change_price.json";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import {CisUI} from "../../../../util/CISUI";
import {useHistory} from "react-router-dom";

const {Title} = Typography;
const {Option} = Select;
const {TextArea} = Input;

const NewPrice = (props) => {
    const [products, setProducts] = useState([])
    const [selectProduct, setSelectProduct] = useState([])

    const userID = props.match.params.id;
    let userData = [];
    const [form] = Form.useForm();

    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectTo = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectTo = "../../" + jsonfile.urls.list;
    }

    const getproduct = () => {
        axios.get(config.apiserver + "stores/listProduct?isDropDown=1", CisUI().HeaderRequest)
            .then((res) => {
                console.log(res);
                setProducts(res.data.data)
            }).catch((errors) => console.log(errors));
    };

    const getSelectProduct = (event) => {
        console.log(event)
        axios.get(config.apiserver + `stores/showProduct/${event}`, CisUI().HeaderRequest)
            .then((res) => {
                console.log(res);
                setSelectProduct(res.data.data)
            }).catch((errors) => console.log(errors));
    }
    useEffect(() => {
        getproduct()
    }, [])

    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values)
        const data = {
            ...values,
            'dealer_price': values['dealer_price'] === undefined || null ? selectProduct.dealer_price : values['dealer_price']
        }
        console.log(data)

        axios.post(config.apiserver + `stores/addNewPrice`, values, CisUI().HeaderRequest)
            .then((res) => {
                console.log(res);
                notification.success({
                    message: "Success",
                    description: "Successfully Update Price",
                });
                // setSelectProduct(res.data.data)
                history.push("../stores/pdpricesetup");
            }).catch((errors) => {
            notification.error({
                message: "Error",
                description: "Price not Update",
            });
        });
    };
    return (
        <Card title={<IntlMessages id={title}/>} extra={
            isEdit === 1
                ? CisUI().addAction(redirectTo)
                : CisUI().addAction(redirectTo)
        }>
            <Form
                {...CisUI().formItemLayout}
                onFinish={onFinish}
                // form={form}
            >
                {/*<Title className="product-title" level={2}>Change Product Price</Title>*/}
                <Row style={{rowGap: "15px"}}>
                    <Col lg={6} xs={24}>

                    </Col>
                    <Col lg={12} xs={24}>
                        <Form.Item label="Select Product" rules={[{required: true, message: "Select a Product"}]}
                                   className="form-input"
                                   name="product_id"
                                   extra={selectProduct == "" ? "" : `Product SKU : ${selectProduct?.sku}`}>
                            <Select placeholder="Select An category" style={{width: "100%"}}
                                    onChange={getSelectProduct}>

                                {products?.map((product, index) => (
                                    <Option key={++index} value={product.id}>
                                        {product.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6} xs={24}>

                    </Col>
                    <Divider orientation="left" className="product-divider"><Title className="add-product-title"
                                                                                   level={4}>Change
                        Pricing</Title></Divider>

                    <Col lg={8} xs={24}>
                        <Form.Item
                            extra={selectProduct == "" ? "" : `Current Price : ${selectProduct?.retail_price}`}
                            label="Retail Price"
                            name="retail_price"
                            rules={[
                                {required: true, message: "Selling Price is required"},
                            ]}
                            className="form-input"
                        >
                            <InputNumber className="gx-mb-1 gx-w-100" placeholder="Selling Price"/>
                        </Form.Item>
                    </Col>
                    {/*<Col lg={8} xs={24}>*/}
                    {/*    <Form.Item*/}
                    {/*        extra={selectProduct == "" ? "" : `Current Price : ${selectProduct?.dealer_price}`}*/}
                    {/*        label="Dealer Price"*/}
                    {/*        name="dealer_price"*/}
                    {/*        className="form-input"*/}
                    {/*    >*/}
                    {/*        <InputNumber className="gx-mb-1 gx-w-100" placeholder="Dealer Price"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col lg={8} xs={24}>*/}
                    {/*    <Form.Item*/}
                    {/*        extra={selectProduct == "" ? "" : `Current Price : ${selectProduct?.distributor_price}`}*/}
                    {/*        label="Distributor Price"*/}
                    {/*        name="distributor_price"*/}
                    {/*        className="form-input"*/}
                    {/*    >*/}
                    {/*        <InputNumber className="gx-mb-1 gx-w-100" placeholder="Distributor Price"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col lg={8} xs={24}>*/}
                    {/*    <Form.Item*/}
                    {/*        extra={selectProduct == "" ? "" : `Current Price : ${selectProduct?.wholesale_price}`}*/}
                    {/*        label="Wholesale Price"*/}
                    {/*        name="wholesale_price"*/}
                    {/*        className="form-input"*/}
                    {/*    >*/}
                    {/*        <InputNumber className="gx-mb-1 gx-w-100" placeholder="Wholesale Price"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}

                    {/*<Divider orientation="left" className="product-divider"><Title className="add-product-title"*/}
                    {/*                                                               level={4}>Change*/}
                    {/*    Commission</Title></Divider>*/}


                    {/*<Col lg={8} xs={24}>*/}
                    {/*    <Form.Item*/}
                    {/*        extra={selectProduct == "" ? "" : `Current Price : ${selectProduct?.retail_commission}`}*/}
                    {/*        label="Retail Commission"*/}
                    {/*        name="retail_commission"*/}
                    {/*        className="form-input"*/}

                    {/*    >*/}
                    {/*        <InputNumber className="gx-mb-1 gx-w-100" placeholder="Retail Commission"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col lg={8} xs={24}>*/}
                    {/*    <Form.Item*/}
                    {/*        label="Dealer Commission"*/}
                    {/*        name="dealer_commission"*/}
                    {/*        className="form-input"*/}
                    {/*        extra={selectProduct == "" ? "" : `Current Price : ${selectProduct?.dealer_commission}`}*/}
                    {/*    >*/}
                    {/*        <InputNumber className="gx-mb-1 gx-w-100" placeholder="Dealer Commission"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col lg={8} xs={24}>*/}
                    {/*    <Form.Item*/}
                    {/*        label="Distributor Commission"*/}
                    {/*        name="distributor_commission"*/}
                    {/*        className="form-input"*/}
                    {/*        extra={selectProduct == "" ? "" : `Current Price : ${selectProduct?.distributor_commission}`}*/}
                    {/*    >*/}
                    {/*        <InputNumber className="gx-mb-1 gx-w-100" placeholder="Distributor Commission"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}

                    {/*<Col lg={8} xs={24}>*/}
                    {/*    <Form.Item*/}
                    {/*        label="Wholesale Commission"*/}
                    {/*        name="wholesale_commission"*/}
                    {/*        className="form-input"*/}
                    {/*        extra={selectProduct == "" ? "" : `Current Price : ${selectProduct?.wholesale_commission}`}*/}
                    {/*    >*/}
                    {/*        <InputNumber className="gx-mb-1 gx-w-100" placeholder="Wholesale Commission"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}


                    <Divider orientation="left" className="product-divider"><Title className="add-product-title"
                                                                                   level={4}>Remark</Title></Divider>
                    <Col lg={16} xs={24}>
                        <Form.Item
                            label="Description"
                            name="prodcut_description"
                            className="form-input"
                        >
                            <TextArea rows={4}/>
                        </Form.Item>
                    </Col>

                    <Col lg={8} xs={24}>
                        <Form.Item
                            label="Status"
                            initialValue={1}
                            rules={[
                                {
                                    required: true,
                                    message: "Status is required",
                                },
                            ]}
                            name="status"
                            className="form-input"
                        >
                            <Select
                                placeholder="Select An Option"
                                style={{width: "100%"}}
                            >
                                <Option value="1">Active</Option>
                                <Option value="0">Inactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={24} xs={24} style={{textAlign: "center"}}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button type="secondary" htmlType="reset">
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>

    )
}

export default NewPrice

import React, { useEffect, useState } from "react";
import {
    Col,
    Input,
    Row,
    Select,
    Form,
    Button,
    Card,
    Divider,
    Typography,
    Upload,
    Modal,
    message,
    InputNumber
} from "antd";
import { useHistory } from "react-router-dom";
import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import config from "../../../../util/config";
import axios from "axios";
import DynamicField from "../../../../components/DynamicField/DynamicField";
import IntlMessages from "../../../../util/IntlMessages";
import { CisUI } from "../../../../util/CISUI";
import jsonfile from "./product.json";
import StaticSelectedData from "../../../../util/StaticSelectData";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;


const AddProduct = () => {
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [vendorTypes, setVendorTypes] = useState([]);
    const [flag, setFlag] = useState(false)
    const [businessType, setBusinessType] = useState([]);
    const [isModalVendor, setIsModalVendor] = useState(false);
    const [isModalCategory, setIsModalCategory] = useState(false);
    const [isModalSubCategory, setIsModalSubCategory] = useState(false);
    const [file, setFile] = useState("");
    const [subCatIsDisabled, setSubCatIsDisabled] = useState(true);
    // const [form] = Form.useForm();
    let title = jsonfile.addtitle;
    // let endpoint = jsonfile.urls.add;
    let redirectTo = "../" + jsonfile.urls.list;

    // const business_type = StaticSelectedData("business_type")
    // console.log(business_type);

    const showCategoryModal = () => {
        setIsModalCategory(true)
    }

    const showSubCategoryModal = () => {
        setIsModalSubCategory(true)
    }

    const showVendorModal = () => {
        setIsModalVendor(true)
    }

    const handleCancel = () => {
        setIsModalVendor(false);
        setIsModalCategory(false)
        setIsModalSubCategory(false)
    };

    const getCategories = () => {
        axios.get(config.apiserver + "stores/listCategory", CisUI().HeaderRequest)
            .then((res) => setCategories(res.data.data))
            .catch((errors) => console.log(errors));
    };
    // const getSubCategory = () => {
    //     axios.get(config.apiserver + "stores/subcategory", CisUI().HeaderRequest)
    //         .then((res) => {
    //             console.log(res);
    //             setSubCategories(res.data.data)
    //         }).catch((errors) => console.log(errors));
    // };

    const getVendorTypes = () => {
        axios.get(config.apiserver + "vendor/vendortype", CisUI().HeaderRequest)
            .then((res) => {
                console.log(res);
                setVendorTypes(res.data.data)
            }).catch((errors) => console.log(errors));
    };

    const getVendors = () => {
        axios.get(config.apiserver + "vendor/vendorinfo", CisUI().HeaderRequest)
            .then((res) => {
                console.log(res);
                setVendors(res.data.data)
            }).catch((errors) => console.log(errors));
    };


    const categoryOnFinish = (values) => {
        axios.post(config.apiserver + "stores/addCategory", values, CisUI().HeaderRequest)
            .then((res) => {
                if (res.data.status == 1) {
                    setIsModalCategory(false)
                    setFlag(!flag)
                } else {
                    console.log("Error");
                }
            }).catch((errors) => console.log(errors));
    };

    const subCategoryOnFinish = (values) =>{
        console.log("hello");
        axios.post(config.apiserver + "stores/addSubCategory", values, CisUI().HeaderRequest)
        .then((res) => {
            if (res.data.status == 1) {
                setIsModalSubCategory(false)
                setFlag(!flag)
            } else {
                console.log("Error");
            }
        }).catch((errors) => console.log(errors));
    }

    const vendorOnFinish = (values) =>{
        console.log(values);
        axios.post(config.apiserver + "vendor/newvendorinfo", values, CisUI().HeaderRequest)
        .then((res) => {
            if (res.data.status == 1) {
                setIsModalVendor(false)
                setFlag(!flag)
            } else {
                console.log("Error");
            }
        }).catch((errors) => console.log(errors));
    }
    

    const onFinish = (values) => {
        let data = {
            ...values,
            image: file
        }
        document.getElementById("loader").style.display = "block";
        axios.post(config.apiserver + "stores/addProduct", data, CisUI().HeaderRequest)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    history.push("../stores/products");
                } else {
                    console.log("Error");
                }
            })
            .catch((errors) => {
                document.getElementById("loader").style.display = "none";
                console.log(errors)
            });
    };

    useEffect(() => {
        setBusinessType(StaticSelectedData("business_type"));
        getVendorTypes()
    }, []);

    useEffect(() => {
        // getSubCategory();
        getCategories();
        getVendors()
    }, [flag]);

    // const getBase64 = (img, callback) => {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        // const reader = new FileReader();
        // reader.addEventListener('load', () => {
        //     console.log(reader)
        //     setFile(reader.result)
        // });
        // reader.readAsDataURL(file);
        setFile(file)
        return false;
    };


    // const handleImageChange = (info) => {
    //     console.log("info", info);
    //     setFile(info.fileList[0].originFileObj);
    //     // if (info.file.status === "uploading") {
    //     //   console.log("Uploading");
    //     // }
    //     // if (info.file.status === "done") {
    //     //   console.log("Done");
    //     //   message.success(${info.file.name} file uploaded successfully);
    //     //   getBase64(info.file.originFileObj, (imageUrl) => {
    //     //     setSignatureData(imageUrl);
    //     //   });
    //     // } else if (info.file.status === "error") {
    //     //   console.log("Error");
    //     //   message.error(${info.file.name} file upload failed.);
    //     // }
    //     // getBase64(info.file.originFileObj, (imageUrl) => {
    //     //     console.log("info1", imageUrl);
    //     //     setFile(imageUrl);
    //     // });
    // };

    const handleRemove = (file) => {
        console.log("remove", file)
        setFile("")
    }
    
    const handleCategoryChange = (e) => {
        // alert("category:" + e)
        setSubCatIsDisabled(true);
        axios.get(config.apiserver + "stores/subcategory?category_id="+parseInt(e), CisUI().HeaderRequest)
            .then((res) => {
                console.log(res);
                if(res.data.status === 1) {
                    setSubCategories(res.data.data)
                }
                setSubCatIsDisabled(false)
            })
            .catch((errors) => console.log(errors));
        
    }

    return (
        <>
            {CisUI().showLoading}
            <Card title={<IntlMessages id={title} />} extra={CisUI().addAction(redirectTo)}>
                <Form
                    {...CisUI().formItemLayout}
                    onFinish={onFinish}>
                    <Row className="ant-row" style={{ rowGap: "15px" }} justify="space-between">
                        <Divider orientation="left" className="product-divider">
                            <Title className="add-product-title" level={4}>Product information</Title>
                        </Divider>
                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Product Name"
                                rules={[
                                    { required: true, message: "Product Name is required" },
                                ]}
                                name="name"
                                className="form-input"
                            >
                                <Input placeholder="Product Name" />
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item label="Product SKU"
                                rules={[{ required: true, message: "Product SKU is required" }]}
                                name="sku" className="form-input">
                                <Input placeholder="Product SKU" />
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Select category"
                                name="category_id"
                                className="form-input"
                                rules={[
                                    { required: true, message: "Category Name is required" },
                                ]}
                            >
                                <Select
                                    placeholder="Select An category"
                                    suffixIcon={<PlusCircleOutlined onClick={showCategoryModal} />}
                                    onChange={handleCategoryChange}
                                >
                                    {categories.map((category, index) => (
                                        <Option key={++index} value={category.id}>
                                            {category.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Select Subcategory"
                                name="subcategory_id"
                                className="form-input"
                                rules={[
                                    { required: true, message: "Subcategory Name is required" },
                                ]}
                            
                            >
                                <Select
                                    placeholder="Select An sub category"
                                    style={{ width: "100%" }}
                                    suffixIcon={<PlusCircleOutlined onClick={showSubCategoryModal} />}
                                    disabled={subCatIsDisabled}
                                >
                                    {subCategories.map((category, index) => (
                                        <Option key={++index} value={category.id}>
                                            {category.name}
                                        </Option>
                                    ))}
                                </Select>
                                
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Select Vendor"
                                rules={[{ required: true, message: "Vendor is required" }]}
                                name="vendor_id"
                                className="form-input"

                            >
                                <Select
                                    placeholder="Select An Vendor"
                                    style={{ width: "100%" }}
                                
                                    suffixIcon={<PlusCircleOutlined onClick={showVendorModal} />}
                                >
                                    {vendors.map((item, index) => (
                                        <Option key={++index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Product Unique Code"
                                initialValue={"No Serial"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Type is required",
                                    },
                                ]}
                                name="type"
                                className="form-input"
                            >
                                <Select
                                    placeholder="Select An Option"
                                    style={{ width: "100%" }}
                                >
                                    <Option value="Serial Use">Serial Use</Option>
                                    <Option value="No Serial">No Serial</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Stock Count"
                                name="stock_quantity"
                                className="form-input"
                                initialValue={1}
                            >
                                <Select
                                    placeholder="Select An Option"
                                    style={{ width: "100%" }}
                                >
                                    <Option value={1}>Yes</Option>
                                    <Option value={0}>No</Option>
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Description"
                                name="prodcut_description"
                                className="form-input"
                            >
                                <TextArea rows={1} />
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Status"
                                rules={[
                                    {
                                        required: true,
                                        message: "User Status is required",
                                    },
                                ]}
                                name="status"
                                className="form-input"
                                initialValue={1}
                            >
                                <Select
                                    placeholder="Select An Option"
                                    style={{ width: "100%" }}
                                >
                                    <Option value={1}>Active</Option>
                                    <Option value={0}>Inactive</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Divider orientation="left" className="product-divider"><Title className="add-product-title"
                            level={4}>Pricing</Title></Divider>


                        <Col lg={8} xs={24}>
                            <Form.Item
                                label="Selling Price"
                                name="retail_price"
                                rules={[
                                    { required: true, message: "Selling Price is required" },
                                ]}
                                className="form-input"
                            >
                                <InputNumber min={0} className="gx-mb-3 gx-w-100" controls={false} placeholder="Selling Price" />
                            </Form.Item>
                        </Col>
                        {/*<Col lg={8} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Dealer Price"*/}
                        {/*        name="dealer_price"*/}
                        {/*        className="form-input"*/}
                        {/*    >*/}
                        {/*        <Input placeholder="Dealer Price" />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col lg={8} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Distributor Price"*/}
                        {/*        name="distributor_price"*/}
                        {/*        className="form-input"*/}
                        {/*    >*/}
                        {/*        <Input placeholder="Distributor Price" />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}

                        {/*<Col lg={8} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Wholesale Price"*/}
                        {/*        name="wholesale_price"*/}
                        {/*        className="form-input"*/}
                        {/*    >*/}
                        {/*        <Input placeholder="Wholesale Price" />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/* <Divider className="product-divider">Commission</Divider> */}

                        {/*<Divider orientation="left" className="product-divider">*/}
                        {/*    <Title className="add-product-title" level={4}>Commission</Title>*/}
                        {/*</Divider>*/}

                        {/*<Col lg={8} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Retail Commission"*/}
                        {/*        name="retail_commission"*/}
                        {/*        className="form-input"*/}
                        {/*    >*/}
                        {/*        <Input placeholder="Retail Commission" />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col lg={8} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Dealer Commission"*/}
                        {/*        name="dealer_commission"*/}
                        {/*        className="form-input"*/}
                        {/*    >*/}
                        {/*        <Input placeholder="Dealer Commission" />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col lg={8} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Distributor Commission"*/}
                        {/*        name="distributor_commission"*/}
                        {/*        className="form-input"*/}
                        {/*    >*/}
                        {/*        <Input placeholder="Distributor Commission" />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}

                        {/*<Col lg={8} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Wholesale Commission"*/}
                        {/*        name="wholesale_commission"*/}
                        {/*        className="form-input"*/}
                        {/*    >*/}
                        {/*        <Input placeholder="Wholesale Commission" />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}


                        {/* <Divider className="product-divider">Others Information</Divider> */}
                        <Divider orientation="left" className="product-divider">
                            <Title className="add-product-title" level={4}>Add Product Image</Title>
                        </Divider>

                        <Col lg={24} xs={24}>
                            {/*<Form.Item label="Image" name="image" className="form-input">*/}
                            {/*    <input type="file" name="image" />*/}
                            {/*</Form.Item>*/}
                            {/* <ProductImage/> */}
                            <Form.Item label="Image" name="image" className="form-input">
                                <Upload
                                    // action={false}
                                    listType="picture-card"
                                    beforeUpload={beforeUpload}
                                    // onChange={handleImageChange}
                                    onRemove={handleRemove}
                                >
                                    {/*<Button>*/}
                                        <UploadOutlined /> Click to Upload
                                    {/*</Button>*/}
                                </Upload>
                            </Form.Item>


                        </Col>


                        <Divider orientation="left" className="product-divider">
                            <Title className="add-product-title" level={4}>Custom Fields</Title>
                        </Divider>

                        <DynamicField />

                        <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                            <Button type="secondary" htmlType="reset">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            {/*)}*/}

            {/*Add Category Start*/}
            <Modal title="Add Category" visible={isModalCategory} okButtonProps={{ form: 'categoryForm', key: 'submit', htmlType: 'submit' }} okText="Submit" onCancel={handleCancel} width={800}>
                <Form id="categoryForm" {...CisUI().formItemLayout} onFinish={categoryOnFinish} initialValues={{status: "1"}}>
                    <Row className="ant-row" style={{ rowGap: "15px" }} justify="space-between">

                        <Col lg={12} xs={24}>
                            <Form.Item label="Category Name" name="name" className="form-input" rules={[{ required: true, message: "Category Name is required" }]}>
                                <Input placeholder="Enter a Category Name" />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item label="Status" name="status" className="form-input"
                                rules={[{required: true, message: "Status is required"}]}>
                                <Select
                                    placeholder="Select An Option"
                                    style={{ width: "100%" }}
                                >
                                    <Option value="1">Active</Option>
                                    <Option value="0">Inactive</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {/*Add Category End*/}
            {/*Add SubCategory Start*/}
            <Modal title="Add Sub Category" visible={isModalSubCategory} okButtonProps={{ form: 'subCategoryForm', key: 'submit', htmlType: 'submit' }} okText="Submit" onCancel={handleCancel} width={800}>
                <Form id="subCategoryForm" {...CisUI().formItemLayout} onFinish={subCategoryOnFinish} initialValues={{
                    status: "1"
                }}>
                    <Row className="ant-row" style={{ rowGap: "15px" }} justify="space-between">

                        <Col lg={12} xs={24}>
                            <Form.Item label="Category Name" name="name" className="form-input" rules={[{ required: true, message: "Category Name is required" }]}>
                                <Input placeholder="Enter a Category Name" />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                        <Form.Item
                                label="Select Parent Category"
                                name="category_id"
                                className="form-input"
                                rules={[
                                    {
                                        required: true,
                                        message: "Category is required",
                                    },
                                ]}
                            
                            >
                                <Select
                                    placeholder="Select An parent category"
                                    style={{ width: "100%" }}
                                >
                                    {categories.map((category, index) => (
                                        <Option key={++index} value={category.id}>
                                            {category.name}
                                        </Option>
                                    ))}
                                </Select>
                                
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item label="Status" name="status" className="form-input"
                                rules={[
                                    {
                                        required: true,
                                        message: "Status is required",
                                    },
                                ]}>
                                <Select
                                    placeholder="Select An Option"
                                    style={{ width: "100%" }}
                                >
                                    <Option value="1">Active</Option>
                                    <Option value="0">Inactive</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {/*Add Sub Category End*/}
            {/*Add Vendor Start*/}
            <Modal title="Add Vendor" visible={isModalVendor} okButtonProps={{ form: 'vendorForm', key: 'submit', htmlType: 'submit' }} okText="Submit" onCancel={handleCancel} width={800}>
                <Form id="vendorForm" {...CisUI().formItemLayout} onFinish={vendorOnFinish} initialValues={{
                    status: "1"
                }}>
                    <Row className="ant-row" style={{ rowGap: "15px" }} justify="space-between">

                        <Col lg={12} xs={24}>
                            <Form.Item label="Vendor Name" name="name" className="form-input" rules={[{ required: true, message: "Vendor Name is required" }]}>
                                <Input placeholder="Enter a Vendor Name" />
                            </Form.Item>
                        </Col>


                        <Col lg={12} xs={24}>
                            <Form.Item label="Vendor Type" name="vendor_type" className="form-input" rules={[{ required: true, message: "Business Type is required" }]}>
                            <Select
                                    placeholder="Select An Business Type"
                                    style={{ width: "100%" }}
                                    showarrow
                                >
                                    {vendorTypes.map((item, index) => (
                                        <Option key={++index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>



                        <Col lg={12} xs={24}>
                            <Form.Item label="Business Type" name="business_type" className="form-input" rules={[{ required: true, message: "Business Type is required" }]}>
                            <Select
                                    placeholder="Select An Business Type"
                                    style={{ width: "100%" }}
                                    showarrow
                                >
                                    {businessType.map((item, index) => (
                                        <Option key={++index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item label="Status" name="status" className="form-input"
                                rules={[
                                    {
                                        required: true,
                                        message: "Status is required",
                                    },
                                ]}>
                                <Select
                                    placeholder="Select An Option"
                                    style={{ width: "100%" }}
                                >
                                    <Option value="1">Active</Option>
                                    <Option value="0">Inactive</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {/*Add Vendor End*/}
        </>
    );
};

export default AddProduct;

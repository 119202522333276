import React, {useState, useEffect} from "react";
import { Form, Collapse ,Card, notification} from 'antd';
import jsonfile from './ledger.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import AdvancedSearchBeneficiary from "../../../../util/AdvanceSearchWithBeneficiary";
import localStorage from "react-secure-storage";
const Panel = Collapse.Panel;

const CashflowStatementDetails = (props) => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const accountID = params.get("account_id") || 0;
    const date = params.get("date") || "";
    let url = Config.apiserver+jsonfile.urls.cashflowstmnt+"?tp=1";
    if(accountID > 0) {
        url = Config.apiserver+jsonfile.urls.cashflowstmnt+"?tp=1&account_id="+accountID+"&date="+date;
    }

    const [state,setState] = useState({
        reqURL: url,
        filtervalue : '',
        subtitle : '',
    });

    const [dateD,setDateD] = useState("");
    const formRef = React.createRef();
    const [apidata,setAPIData] = useState({});
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);

    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }

        axios.get(urls)
            .then((res) => {
                console.log(res)
                if (res.data.status === 1) {
                    setAPIData(res.data);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                // document.getElementById("loader").style.display = "none";
            })
            .finally(() => {
                setIsLoading(false);
            });

    }


    const getDetailsLink = (id) => {
        const source = "ledger";
        return "../../accounts/ledger?account_id="+id+"&date="+dateD;
    }

    const history = useHistory();

    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.cashflowstmnt + "?tp=1&" + qsup;
        // setData([]);
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };



    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearchBeneficiary(jsonfile.search,"",1,"date",CisUI().PeriodFiled(form),form)}

                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const ListActionButtonReportXLS = (url="",querystring="",apiURL="",dataParameter="",filename="",fullJson={}, subtitle="",printmode="portrait") =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/coploading.gif" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                {
                    jsonfile.urls.hasOwnProperty("list") ?
                        <>
                            <button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIDataXLS(apiURL,querystring)} title="Export to Excel"><i
                                className="fas fa-file-excel"/></button>
                            <button onClick={(e) => print(apiURL,dataParameter,filename)} className="btn btn-export-print btn-circle-md" title="Print"><i
                                className="fas fa-print"/></button>
                        </>
                        :
                        ""
                }
            </div>
        );

    const print = (apiurl,datakey,filename) => {
        window.open(Config.baseurl+"reports/financial.html?url="+window.btoa(apiurl)+"&filename="+filename+"&data="+datakey+"&title=Cash Book","_blank");
    }

    const getAPIDataXLS = (APIUrl, qrystring) => {
        let URL  = APIUrl+"/xls?"+qrystring+"&token="+localStorage.getItem("token") || 0;
        if(APIUrl.includes("?")) {
            const sp = APIUrl.split("?");
            URL  = sp[0]+"/xls?"+sp[1]+"&token="+localStorage.getItem("token") || 0;
        }

        console.log("URL :"+URL);
        window.open(URL,"_blank");
    };

    const getExtra = (ListActionButtonReportXLS("../"+jsonfile.urls.add,"",state.reqURL,"data","cash_book",jsonfile,"Journal", "landscape"));

    return (
        <>
            {searchView}
            <Card title="Cash Book" extra={getExtra}>
                {CisUI().showLoading}

                {apidata?.data?.length > 0 ?
                    <>
                        <div className="row">
                            <div className="col-md-12" style={{textAlign: 'center'}}>
                                <h4 style={{textTransform: 'uppercase'}}>{CisUI().getUserInfo('companyName')}</h4>
                                <h3>Cash Book</h3>
                                <h4>For the period of {CisUI().getFormatedDate(apidata?.account?.fromDate)} to {CisUI().getFormatedDate(apidata?.account?.toDate)}</h4>
                            </div>
                            <hr />
                            <div className="col-md-4" style={{textAlign: 'right'}}></div>
                            <div className="col-md-4" style={{textAlign: 'right'}}></div>
                            <div className="col-md-4" style={{textAlign: 'right'}}>
                                <table className="summaryTable table" align={"right"} style={{width: '300px'}}>
                                    <tr>
                                        <th>Opening Balance</th>
                                        <td>{CisUI().getCurrencyFormated1(apidata?.opening)}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Inflow</th>
                                        <td>{CisUI().getCurrencyFormated1(apidata?.totalIn)}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Outflow</th>
                                        <td>{CisUI().getCurrencyFormated1(apidata?.totalOut)}</td>
                                    </tr>
                                    <tr>
                                        <th>Closing Balance</th>
                                        <td>{CisUI().getCurrencyFormated1(apidata?.closing)}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>



                        <div className="table-responsive">
                            <table className="statement table">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Account</th>
                                    <th>Description</th>
                                    <th>Type</th>
                                    <th>Voucher No</th>
                                    <th>RefNo/Cheque#</th>
                                    <th style={{textAlign : 'right'}}>
                                        <div style={{textAlign: 'right'}}>Inflow ({CisUI().getCurrencySymbol()})</div>
                                    </th>
                                    <th style={{textAlign : 'right'}}><div style={{textAlign: 'right'}}>Outflow ({CisUI().getCurrencySymbol()})</div></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    apidata?.data?.map((itmes,index)=>{
                                        return <tr className={"disp_"+itmes.account_id} >
                                            <td><Link to={getDetailsLink(itmes.account_id)}>{itmes.date}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.account_id)}>{itmes.account}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.account_id)}>{itmes.description}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.account_id)}>{itmes.remark}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.account_id)}>{itmes.voucher_no}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.account_id)}>{itmes.reference_no}</Link></td>
                                            <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.account_id)}>{CisUI().getCurrencyFormated1(itmes.debit)}</Link></td>
                                            <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.account_id)}>{CisUI().getCurrencyFormated1(itmes.credit)}</Link></td>
                                        </tr>
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}} colSpan={6}>Total</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(apidata?.totalIn)}</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(apidata?.totalOut)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </> : <h3 style={{textAlign: 'center'}}>No data found</h3>}

            </Card>
        </>
    );
};

export default CashflowStatementDetails;